(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.retailerDetails.times', {
                url: '/times',
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return $filter('translate')('Delivery Times & Areas');
                    }]
                },
                views: {
                    'detailsTab': {
                        templateUrl: 'template/views/retailer-details/times/index.html',
                        controller: 'RetailerTimesCtrl as timesCtrl'
                    }
                }
            });
        }])
        .controller('RetailerTimesCtrl', ['$scope', 'BranchesService', 'Config', 'SpDeliveryAreasService', 'SP_SERVICES', function ($scope, BranchesService,Config,SpDeliveryAreasService,SP_SERVICES) {
            var timesCtrl = this,
                retailerDetailsCtrl = $scope.retailerDetailsCtrl;

            //== set selected branch to the first one by default
            var selectedBranch = retailerDetailsCtrl.branchesInfo[0];

            //== find branch that set to be default in backend
            var tempSelectedBranch = retailerDetailsCtrl.branchesInfo.filter(function(branch) {
                return branch.isDefault === true;
            })[0];

            //== check if this branch have delivery or pickup times, otherwise it will not be displayed on frontend
            if(tempSelectedBranch && tempSelectedBranch.areas){
                if(tempSelectedBranch.areas.delivery.length || tempSelectedBranch.areas.pickup.length){
                    selectedBranch = tempSelectedBranch;
                }
            }

            if(Config.branch){
                selectedBranch = retailerDetailsCtrl.branchesInfo.find(function (branch) {
                    return branch.id === Config.branch.id
                })
            }


            timesCtrl.today = new Date().getDay();
            timesCtrl.selectedBranch = selectedBranch;
            timesCtrl.setSelectedArea = setSelectedArea;
            timesCtrl.getAreaTimes = getAreaTimes;
            timesCtrl.setSelectedBranch = setSelectedBranch;
            timesCtrl.cityTyped = '';
            timesCtrl.autoComplete = filterAreasAutoComplete;
            timesCtrl.submit = filterAreasSubmit;
            timesCtrl.onTabsChanged = onTabsChanged;


            retailerDetailsCtrl.selectedTab = retailerDetailsCtrl.menuTabs.TIMES;

            function setSelectedArea() {
                if (timesCtrl.selectedBranch.areas.delivery.length) {
                    timesCtrl.selectedArea = timesCtrl.selectedBranch.areas.delivery[0];
                } else if (timesCtrl.selectedBranch.areas.pickup && timesCtrl.selectedBranch.areas.pickup.length) {
                    timesCtrl.selectedArea = timesCtrl.selectedBranch.areas.pickup[0];
                }
            }

            function getAreaTimes() {
                if (!(timesCtrl.selectedBranch.areas.delivery && timesCtrl.selectedBranch.areas.delivery.length) &&
                    !(timesCtrl.selectedBranch.areas.pickup && timesCtrl.selectedBranch.areas.pickup.length)){
                    return;
                }
                timesCtrl.selectedArea && (timesCtrl.selectedArea.times = [{}]);
                BranchesService.getAreaTimes(timesCtrl.selectedBranch.id, (timesCtrl.selectedArea && timesCtrl.selectedArea.id) ||
                    (timesCtrl.selectedBranch.areas.delivery && timesCtrl.selectedBranch.areas.delivery.length && timesCtrl.selectedBranch.areas.delivery[0].id) ||
                    (timesCtrl.selectedBranch.areas.pickup && timesCtrl.selectedBranch.areas.pickup.length && timesCtrl.selectedBranch.areas.pickup[0].id))
                    .then(function(times) {
                        if (!timesCtrl.selectedArea) {
                            setSelectedArea();
                        }
                        timesCtrl.selectedArea.times = _setTimes(times);
                    });
            }

            function setSelectedBranch() {
                setSelectedArea();
                getAreaTimes();
                if (timesCtrl.selectedBranch.areas.pickup && timesCtrl.selectedBranch.areas.pickup.length) {
                    BranchesService.getAreaTimes(timesCtrl.selectedBranch.id, timesCtrl.selectedBranch.areas.pickup[0].id)
                        .then(function(times) {
                            if (!timesCtrl.selectedArea) {
                                setSelectedArea();
                            }
                            timesCtrl.selectedBranch.areas.pickup[0].times = _setTimes(times);
                        });
                }
            }

            function _init() {
                setSelectedBranch();
                if (timesCtrl.selectedBranch.areas.pickup && !timesCtrl.selectedBranch.areas.pickup.length) {
                    retailerDetailsCtrl.deliveryOptionSelected = SP_SERVICES.DELIVERY_TYPES.DELIVERY;
                }

                SpDeliveryAreasService.getChooseAreaMode().then(function(chooseAreMode) {
                    retailerDetailsCtrl.chooseAreaMode = chooseAreMode;
                })
            }

            _init();

            function _setTimes(times) {
                var week = [];
                for (var i = 0; i < 6; i++) {
                    week[i] = [];
                }
                angular.forEach(times, function (time) {
                    week[time.dayInWeek] = week[time.dayInWeek] || [];
                    week[time.dayInWeek].push(time);
                });
                week.forEach(function(day){
                    day.sort(_compareTime);
                })
                return week;
            }

    
            function _compareTime(a, b) {
                var timeA = new Date(a.fromDisplay || a.from);
                var timeB = new Date(b.fromDisplay || b.from);
                return timeA - timeB;
            }

            function filterAreasAutoComplete() {
                timesCtrl.selectedArea = null;
                return SpDeliveryAreasService.autoCompleteDeliveryAreas(retailerDetailsCtrl.chooseAreaMode, timesCtrl.cityTyped);
            }

            function filterAreasSubmit() {
                return SpDeliveryAreasService.filterDeliveryAreas(retailerDetailsCtrl.chooseAreaMode, timesCtrl.cityTyped).then(function (response) {
                    if(response.areas.length > 0) {
                        if(SP_SERVICES.DELIVERY_TYPES.DELIVERY === Number(retailerDetailsCtrl.deliveryOptionSelected)) {
                            timesCtrl.selectedAreaByCity = response.areas[0];
                        }
                        timesCtrl.selectedArea = response.areas[0];
                        timesCtrl.selectedBranchByCity = response.areas[0].branch;
                        timesCtrl.selectedBranchByCity.areas.delivery = response.areas[0].branch.areas;
                        return getAreaTimesByCity();
                    }

                    timesCtrl.selectedBranchByCity = null;
                });
            }

            function getAreaTimesByCity() {
                if (!timesCtrl.selectedBranchByCity && timesCtrl.cityTyped) {
                    timesCtrl.selectedAreaByCity = null;
                    return filterAreasSubmit();
                }

                timesCtrl.selectedArea && (timesCtrl.selectedArea.times = [{}]);
                var selectedBranchByCityId = timesCtrl.selectedBranchByCity && timesCtrl.selectedBranchByCity.id;
                var areaId;
                if (SP_SERVICES.DELIVERY_TYPES.DELIVERY === Number(retailerDetailsCtrl.deliveryOptionSelected)) {
                    areaId = timesCtrl.selectedAreaByCity && timesCtrl.selectedAreaByCity.id;
                } else {
                    areaId = timesCtrl.selectedArea && timesCtrl.selectedArea.id;
                    if (!areaId && timesCtrl.selectedBranchByCity && timesCtrl.selectedBranchByCity.areas && timesCtrl.selectedBranchByCity.areas.delivery && timesCtrl.selectedBranchByCity.areas.delivery.length) {
                        areaId = timesCtrl.selectedBranchByCity.areas.delivery[0].id;
                    }
                }

                if (areaId) {
                    BranchesService.getAreaTimes(selectedBranchByCityId, areaId).then(function (times) {
                            if (!timesCtrl.selectedArea) {
                                setSelectedArea();
                            }
                            timesCtrl.selectedArea.times = _setTimes(times);
                        });
                }
            }

            function onTabsChanged() {
               if(SP_SERVICES.DELIVERY_TYPES.DELIVERY === Number(retailerDetailsCtrl.deliveryOptionSelected)) {
                   return getAreaTimesByCity();
               }

               setSelectedBranch();
            }
        }]);
})(angular, app);
