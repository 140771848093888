(function (angular, backendApp) {
    'use strict';
    function paymentMethodFtr(SP_PAYMENTS, Config) {
        var methods = {}, giftCardNames = {};
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD] = 'Credit card';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.CALL_CUSTOMER] = 'Call customer';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.STORE_CREDIT] = 'Store credit';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL] = 'PayPal';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.ORGANIZATION] = 'Organization';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS] = 'ICreditMasterPass';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.CUSTOMER_CREDIT] = 'Customer_Credit';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.BIT] = 'Bit';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT] = 'EBT';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS] = 'Tav Plus';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.LOYALTY_POINTS] = 'Loyalty Points';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.TEN_BIS] = 'TenBis';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.RAK_BESMACHOT] = 'Rak Besmachot';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.PRAXEL] = 'PRAXEL';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.CIBUS] = 'Cibus';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.HTZONE] = 'Hitech Zone';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.MAX_GIFT_CARD] = 'Max gift card';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.GOLD_NORTH] = 'Gold North';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.DOLCE_VITA] = 'Dolce Vita';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.ISTUDENT] = 'iStudent';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.CAL] = 'Cal';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.DIRECT_INSURANCE_CLUB] = 'Direct Insurance Club';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.YOURS_CLUB] = 'Yours Club';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.PSAGOT] = 'Psagot';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.YERUSHALMI] = 'Yerushalmi';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.LEUMI_GOODIES] = 'Leumi Goodies';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.PAIS_PLUS] = 'Pais Plus';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.RAAYONIT] = 'Raayonit';
        methods[SP_PAYMENTS.PAYMENT_METHODS.IDS.NOFSHONIT] = 'Nofshonit';

        if (Config.retailer.settings.giftCardNamesSettings && Config.retailer.settings.giftCardNamesSettings.constructor === String) {
            Config.retailer.settings.giftCardNamesSettings = JSON.parse(Config.retailer.settings.giftCardNamesSettings);
        } else {
            Config.retailer.settings.giftCardNamesSettings = Config.retailer.settings.giftCardNamesSettings || {};
        }
        
        if (Object.keys(Config.retailer.settings.giftCardNamesSettings).length > 0) {
            giftCardNames = Config.retailer.settings.giftCardNamesSettings;
        }

        function customPaymentName(input, languageId) {
            if (!input) return null;
            if (!giftCardNames[input]) return methods[input];
            return giftCardNames[input][languageId];
        }

        function paymentMethodName(input, isOriginal, translateToLang) {
            if (!input) return;
            
            translateToLang = translateToLang && translateToLang.id ? translateToLang : Config.language;

            if (isOriginal) return methods[input];
            var customName = customPaymentName(input, translateToLang.id);

            if (customName) return customName;
            return methods[input];
        }

        paymentMethodName.$stateful = true;
        return paymentMethodName;
    }

    app.filter('paymentMethod', ['SP_PAYMENTS', 'Config', paymentMethodFtr]);
})(angular, app);