(function (angular, app) {
    'use strict';

    app.service('Config', [
        '$rootScope', '$location', '$q', '$timeout', '$injector', 'LocalStorage', 'SpDeliveryAreasService',
        'FRONTEND_SERVICE_ID', 'HOME_PAGE_SIDE_DISPLAY_TYPES', 'SP_SERVICES', '$filter', 'HE', 'EN', 'ES', 'RU', 'FR', 'AR', 'Api',
        function ($rootScope, $location, $q, $timeout, $injector, LocalStorageService, SpDeliveryAreasService,
                  FRONTEND_SERVICE_ID, HOME_PAGE_SIDE_DISPLAY_TYPES, SP_SERVICES, $filter, he, en, es, ru, fr, ar, Api) {
            var self = this,
                User, Util,
                _branchArea,
                initDefer = $q.defer(),
                APPROVED_COOKIES_STORAGE_KEY = 'approvedCookies';

            self.initPromise = initDefer.promise;

            self.multiRetailers = !!window.sp.frontendData.retailers && window.sp.frontendData.retailers.length > 1;

            self.retailers = window.sp.frontendData.retailers;
            self.retailer = window.sp.frontendData.retailer;
            self.retailer.shopTitle = (self.retailer.settings.shopTitle && JSON.parse(self.retailer.settings.shopTitle)) || self.retailer.title;
            self.hubRetailer = window.sp.frontendData.hubRetailer;
            self.getBranchArea = getBranchArea;
            self.changeBranch = changeBranch;
            self.setApprovedCookies = setApprovedCookies;
            self.waitForInit = waitForInit;
            self.retailerHeavyTagLimitations = [];
            self.mapOfProductTagProducts = {};
            self.mapOfRetailerProductTagProducts = {};
            self.arrayOfProductTagProducts = [];
            self.arrayOfRetailerProductTagProducts = [];
            _setHomePages();
            self.isAreaSelectedByUser = false;
            self.isUserDefaultArea = false;
            self.contactUsPageDesign = {
                isUseContactPage: self.retailer.settings.isUseContactPage === 'true',
                content: self.retailer.contactUsPageDesign,
            };

            SpDeliveryAreasService.setRetailerConfigurations(self.retailer);
            SpDeliveryAreasService.setMultiRetailers(self.retailers || [self.retailer]);
            // in timeout to prevent circular dependency
            $timeout(function() {
                SpDeliveryAreasService.setOrganizationBranches(_getUser().session.organizationBranches || null);
                _initHeavyTagLimitations();
                _isExistSendXGetYPromotion().then(function(response) {
                    self.isExistSendXGetYPromotion  = response.isExistSendXGetYPromotion ;
                });
            });

            var languages = {
                he: he,
                en: en,
                es: es,
                ru: ru,
                fr: fr,
                ar: ar,
            };

            var retailerLanguages = self.retailer.languages;

            if(self.retailer.settings && self.retailer.settings.activeLanguages) {
                retailerLanguages = JSON.parse(self.retailer.settings.activeLanguages);

                if(retailerLanguages.length) {
                    retailerLanguages = retailerLanguages.map(function(languageId) {
                        return {
                            languageId: languageId
                        }
                    })
                }
            }

            if (retailerLanguages && retailerLanguages.length) {
                self.languages = {};
                angular.forEach(retailerLanguages, function(lang) {
                    angular.forEach(languages, function (value, key) {
                        if (lang.languageId == value.id) {
                            self.languages[key] = value;
                        }
                    });
                })
            } else {
                self.languages = languages;
            }

            self.salesImageUrl = 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/specials-icon.png';
            self.salesImageUrlWhite = 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/specials-icon-white.png';
            self.salesImageCategory = 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/category-sale-menu.png';
            self.isSpecialCategory = {};
            self.language = self.languages[LocalStorageService.getItem('language') || self.retailer.culture] || self.languages[Object.keys(self.languages)[0]];

            self.approvedCookies =  LocalStorageService.getItem(APPROVED_COOKIES_STORAGE_KEY);

            self.isUs = self.retailer.currencySymbol === '$';

            self.isPrerender = navigator.userAgent.includes('SPPrerenderService;');
            self.preventDialogsOnLoad = _getPreventDialogsOnLoad();

            $q.all([
                _initBranch(),
                _initIsZipCodeArea(),
                _initChooseAreaEvent(),
            ]).then(function() {
                 initDefer.resolve();
            });

            // added to have consistent api with mobile - used in sp-services - DataLayer
            function waitForInit() {
                return self.initPromise;
            }

            function _initBranch() {
                return SpDeliveryAreasService.initBranchAndArea({
                    branchId: Number(LocalStorageService.getItem('branchId')),
                    branchAreaId: Number(LocalStorageService.getItem('areaId')),
                    branchAreaText: LocalStorageService.getItem('area'),
                    queryBranchId: Number($location.search().branchId),
                    queryZipCode: $location.search().zip,
                    isPrerender: self.isPrerender
                }).then(function(response) {
                    // no need to use response.isQueryBranch,
                    // it is handled in the local storage service with the login as logic

                    self.branch = response.branch;
                    if (response.branch) {
                        LocalStorageService.setItem('branchId', response.branch.id);
                    }

                    self.area = response.branchAreaText;
                    self.branchAreaId = response.branchAreaId;
                    // if (response.branchAreaId) {
                        // LocalStorageService.setItem('area', response.branchAreaText);
                        // LocalStorageService.setItem('areaId', response.branchAreaId);
                    // }

                    var organizationId = _getUser().session.branchKeyOrganization,
                        treeBranch = self.branch || self.retailer.branches.find(function(branch) {
                            return branch.isDefault && branch.isOnline;
                        }) || self.retailer.branches.find(function(branch) {
                            return branch.isOnline;
                        }) || self.retailer.branches[0],
                        branchIdKey = treeBranch.id + (organizationId ? '_' + organizationId : '');
                    if (treeBranch) {
                        self.tree = window.sp.frontendData.tree;
                        for (var i = self.tree.categories.length - 1; i >= 0; i--) {
                            if (!_isCategoryVisible(self.tree.categories[i], branchIdKey, organizationId)) {
                                self.tree.categories.splice(i, 1);
                                continue;
                            }

                            for (var ii = self.tree.categories[i].subCategories.length - 1; ii >= 0; ii--) {
                                if (!_isCategoryVisible(self.tree.categories[i].subCategories[ii], branchIdKey, organizationId)) {
                                    self.tree.categories[i].subCategories.splice(ii, 1);
                                    continue;
                                }

                                for (var iii = self.tree.categories[i].subCategories[ii].subCategories.length - 1; iii >= 0; iii--) {
                                    if (!_isCategoryVisible(self.tree.categories[i].subCategories[ii].subCategories[iii], branchIdKey, organizationId)) {
                                        self.tree.categories[i].subCategories[ii].subCategories.splice(iii, 1);
                                    }
                                }
                            }
                        }

                        var creditCard3DSModesObject = {};
                        angular.forEach(treeBranch.settings && treeBranch.settings.creditCard3DSModes || self.retailer.settings.creditCard3DSModes || [], function (modeId) {
                            creditCard3DSModesObject[modeId] = true;
                        });

                        self.creditCardConfig = {
                            mode: treeBranch.settings && treeBranch.settings.creditCardMode || self.retailer.settings.creditCardMode,
                            paymentsNumber: treeBranch.settings && treeBranch.settings.paymentsNumber || self.retailer.settings.paymentsNumber,
                            creditCard3DSModes: creditCard3DSModesObject
                        };
                    }
                });
            }

            function _initHeavyTagLimitations () {
                return getHeavyTagLimitations().then(function (quantityLimitations) {
                    self.retailerHeavyTagLimitations = quantityLimitations.heavyPackageLimits;
                    self.mapOfProductTagProducts = quantityLimitations.mapOfProductTagProducts;
                    self.mapOfRetailerProductTagProducts = quantityLimitations.mapOfRetailerProductTagProducts;
                    self.arrayOfProductTagProducts = quantityLimitations.mapOfProductTagProducts ? Object.values(quantityLimitations.mapOfProductTagProducts).flat() : [];
                    self.arrayOfRetailerProductTagProducts = quantityLimitations.mapOfRetailerProductTagProducts ? Object.values(quantityLimitations.mapOfRetailerProductTagProducts).flat() : [];
                });
            }

            function getHeavyTagLimitations () {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/limit-configurations/limitations'
                });
            }

            function _isCategoryVisible(category, branchIdKey, organizationId) {
                var isVisible = !!category.branches[branchIdKey] && !!category.branches[branchIdKey].isVisible;
                if (!isVisible) {
                    return false;
                }

                if (category.organizations && organizationId) {
                    var categoryOrg = category.organizations.find(function(org) {
                        return org.id === organizationId;
                    });

                    if (categoryOrg && !categoryOrg.isVisible) {
                        return false;
                    }
                }

                return true;
            }

            function _getUser() {
                return User || $injector.get('User');
            }

            function _getUtil() {
                return Util || $injector.get('Util');
            }

            function _initIsZipCodeArea() {
                return SpDeliveryAreasService.isZipCodeArea().then(function(value) {
                    self.isZipCodeArea = value;
                });
            }

            function _initChooseAreaEvent() {
                return SpDeliveryAreasService.getChooseAreaEvent().then(function(value) {
                    self.chooseAreaEvent = value;
                });
            }

            function _getPreventDialogsOnLoad() {
                var localStorageValue = !!LocalStorageService.getItem('preventDialogsOnLoad');
                if (localStorageValue) {
                    LocalStorageService.removeItem('preventDialogsOnLoad');
                    return localStorageValue;
                }

                return self.isPrerender;
            }

            function _setContactPhone() {
                self.retailer.contactPhone = self.retailer.contactPhone || (self.branch && self.branch.phone);
            }

            function _setHomePages() {
                self.retailer.homeSideImages = {};
                self.retailer.homeCarouselImages = [];
                var availableBanners = [];
                angular.forEach(self.retailer.homePageImages, function (image) {
                    if (image.serviceId !== FRONTEND_SERVICE_ID) {
                        return;
                    }
                    if (image.type === HOME_PAGE_SIDE_DISPLAY_TYPES.TOP || image.type === HOME_PAGE_SIDE_DISPLAY_TYPES.BOTTOM) {
                        self.retailer.homeSideImages[image.type] = image;
                        return;
                    }
                    availableBanners.push(image);
                    if (image.activeStartDate && image.activeEndDate && !_isAvailableBannerShowTime(image)) {
                        return;
                    }
                    if (!image.activeStartDate && !image.activeEndDate && image.isActive === false) {
                        return;
                    }
                    self.retailer.homeCarouselImages.push(image);
                });
                var firstBanner = angular.copy($filter('orderObjectBy')(availableBanners, 'sort')[0]);
                firstBanner = firstBanner ? [firstBanner] : [];
                // it there is not any banners set first one
                self.retailer.homeCarouselImages = self.retailer.homeCarouselImages.length ? self.retailer.homeCarouselImages : firstBanner;

                self.retailer.homeCarouselImages = $filter('orderObjectBy')(self.retailer.homeCarouselImages, 'sort');

                _setHomePageVideos(self.retailer.homePageVideos);

                self.retailer.hasHomeSideImages = !self.retailer.homePageActiveVideo && Object.keys(self.retailer.homeSideImages).length === 2;
            }

            function _isAvailableBannerShowTime(image) {
                var flag = false;
                if (image.activeStartDate && image.activeEndDate) {
                    var now = new Date();
                    var activeStartDate = new Date(image.activeStartDate);
                    var activeEndDate = new Date(image.activeEndDate);
                    flag = activeStartDate <= now && now < activeEndDate;
                }
                return flag;
            }

            function _setHomePageVideos(videos) {
                var filteredVideos = (videos || []).filter(function(video) {
                    return video.serviceId === FRONTEND_SERVICE_ID;
                });

                if (filteredVideos.length) {
                    self.retailer.homePageActiveVideo = filteredVideos[0];
                }
            }

            function getBranchArea() {
                if (_branchArea && _branchArea.id === self.branchAreaId) {
                    return _branchArea;
                }

                if (!self.branch) {
                    throw new Error('Please wait for the config to be initiated (using the initPromise)');
                }

                _branchArea = self.branch.areas.find(function(area){
                    return area.id === parseInt(self.branchAreaId);
                });

                return _branchArea;
            }

            /**
             * Check order's Branch data and if differs from current (config) we switching Branch
             * @public
             *
             * @param {number} branchId
             * @param {number} branchAreaId
             * @param {Object} options
             * @param {boolean} [options.forceBranchChange]
             * @param {string} [options.typedArea]
             * @param {number} [options.newRetailerId]
             *
             * @returns {Promise}
             */
            function changeBranch(branchId, branchAreaId, options) {
                options = options || {};

                return initDefer.promise.then(function () {
                    var currentBranchId = self.branch && self.branch.id ? self.branch.id : 0,
                        currentBranchAreaId = self.branchAreaId ? self.branchAreaId : 0,
                        newBranchId = branchId,
                        newBranchAreaId = branchAreaId;

                    //== check we have a new branch data to switch to
                    if (!newBranchId || !newBranchAreaId) {
                        return false;
                    }

                    //= check if current branch and area are different from those where order was made
                    if (currentBranchId === newBranchId && currentBranchAreaId === newBranchAreaId) {
                        return false;
                    }

                    var branch = _getBranchByIdFromConfig(newBranchId, options.newRetailerId);
                    if (!branch) {
                        return false;
                    }

                    var area = _getAreaByIdFromBranch(branch, newBranchAreaId);

                    // when force branch change, find the default area when area does not exist
                    if (options.forceBranchChange && !area) {
                        // when the branch is not actually changing, try to stay on the same area
                        if (currentBranchId === newBranchId) {
                            area = _getAreaByIdFromBranch(branch, self.branchAreaId)
                        }

                        area = area || _getDefaultAreaFromBranch(branch);

                        if (area) {
                            newBranchAreaId = area.id;
                        }
                    }

                    if (!area) {
                        return false;
                    }

                    return SpDeliveryAreasService.getChooseAreaMode(false).then(function(chooseAreaMode) {
                        var areaName = area.name;
                        if (area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP && chooseAreaMode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_AREA && area.names && area.names.length) {
                            areaName = options.typedArea || area.names[0].name;
                        }

                        //== set a Global config data - those will set a required branch after reload
                        self.area = areaName;
                        self.branch = branch;
                        self.branchAreaId = newBranchAreaId;
                        if(self.isAreaSelectedByUser || self.isUserDefaultArea) {
                            LocalStorageService.setItem('area', self.area);
                        }


                        //== reload only if branch is changed, not when area changed
                        if (currentBranchId !== newBranchId) {
                            if (!options.newRetailerId || options.newRetailerId === self.retailer.id) {
                                //== set this to true for skipping different popups on page reload
                                LocalStorageService.setItem('preventDialogsOnLoad', 'true');
                            }

                            //== reload after $timeout for the config watchers to finish
                            $timeout(function() {
                                _getUtil().reload(2000);
                            });
                            return true;
                        }
                        return false;
                    });
                });
            }

            /**
             * Get Branch object by Branch Id from the config object
             * @private
             *
             * @param {Number} branchId
             * @param {Number} retailerId
             *
             * @returns {Object} area
             */
            function _getBranchByIdFromConfig(branchId, retailerId){
                var retailer = _getRetailerByIdFromConfig(retailerId);

                if (retailer && retailer.branches) {
                    for (var i = 0; i < retailer.branches.length; i++) {
                        if (retailer.branches[i].id === branchId) {
                            if (!retailer.branches[i].isDisabled && retailer.branches[i].isOnline &&
                                (retailer.id !== self.retailer.id || _getUser().isOrganizationBranch(retailer.branches[i].id))) {
                                return retailer.branches[i];
                            } else {
                                return;
                            }
                        }
                    }
                }
            }

            /**
             * Get Retailer object - for Multi Retailer we need to get new retailer
             * @private
             *
             * @param {Number} retailerId
             *
             * @returns {Object} Retailer
             */
            function _getRetailerByIdFromConfig(retailerId){
                var currentRetailerId = self.retailer ? self.retailer.id : 0;

                // if new Retailer Id not provided or it's the same - return current retailer from config
                if (!retailerId || currentRetailerId === retailerId) {
                    return self.retailer;
                }

                // if no new retailer will be found it's better to return an existing one
                if (self.retailers) {
                    for (var i = 0; i < self.retailers.length; i++) {
                        if(self.retailers[i].id === retailerId){
                            return self.retailers[i];
                        }
                    }
                }

                return self.retailer;
            }

            /**
             * Get Area object by Area Id from the branch object
             * @private
             *
             * @param {Object} branch
             * @param {Number} orderBranchAreaId
             *
             * @returns {Object} area
             */
            function _getAreaByIdFromBranch(branch, orderBranchAreaId) {
                return (branch && branch.areas || []).find(function(area) {
                    return area.id === orderBranchAreaId;
                });
            }

            /**
             * Get the default area object from the branch object
             * @private
             *
             * @param {Object} branch
             *
             * @returns {Object} area
             */
            function _getDefaultAreaFromBranch(branch) {
                var areas = branch && branch.areas || [];

                var withNames = areas.find(function(area) {
                    return area.names && area.names.length;
                });

                return withNames || areas[0];
            }

            $rootScope.$watch(function () {
                return self.language;
            }, function (newValue) {
                LocalStorageService.setItem('language', newValue.culture);
                $rootScope.$emit('config.language.change', newValue);
                SpDeliveryAreasService.setLanguageId(newValue.id);

                window.nl_lang = self.language.culture === 'he' ? self.language.culture : 'en'; // nagishli only supports he & en
                window.nl_pos = 'b' + self.language.direction[0]; // nagishli

                _setNagishLiPosition();
                var util = _getUtil();
                util.createSearchList();
            });

            function _setNagishLiPosition() {
                if (window.moveNagishLi) {
                    window.moveNagishLi(window.nl_pos.toUpperCase());
                } else {
                    setTimeout(_setNagishLiPosition, 500);
                }
            }

            $rootScope.$watch(function () {
                return self.branchAreaId;
            }, function (newValue) {
                if(self.isAreaSelectedByUser || self.isUserDefaultArea) {
                    LocalStorageService.setItem('areaId', newValue);
                }

                $rootScope.$emit('config.branchAreaId.change', newValue);
            });

            $rootScope.$watch(function () {
                return self.branch;
            }, function(newValue) {
                LocalStorageService.setItem('branchId', newValue ? newValue.id : null);
                var searchParams = $location.search();
                if (newValue && searchParams.token && searchParams.userId) {
                    $location.search('branchId', newValue.id);
                }
                _setContactPhone(); // It should take the retailer phone or the branch phone (on change branch it's reloading)
                $rootScope.$emit('config.branch.change', newValue);
            });

            function setApprovedCookies(approvedCookies) {
                self.approvedCookies = approvedCookies;
                LocalStorageService.setItem(APPROVED_COOKIES_STORAGE_KEY, approvedCookies);
                localStorage.setItem('gaAllowed', approvedCookies.googleAnalytics);
            }

            function _isExistSendXGetYPromotion() {
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/promotions/has-sendX-getY-promotion'
                });
            }
        }]);

})(angular, app);
