(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.categoryProducts', {
                url: '/categories/:cid/products?{filters:json}&{sort:json}&{events:json}',
                data: {
                    name: 'category-products'
                },
                reloadOnSearch: false,
                resolve: {
                    categories: ['$rootScope','$state', '$stateParams', 'Util', function ($rootScope,$state, $stateParams, util) {
                        return util.getCategory($stateParams.cid).then(function(categories) {
                            if (!categories) {
                                $state.go('app.home');
                                throw new Error('Category not found');
                            }
                            $rootScope.$emit('categoryPage',categories);
                            return categories;
                        });
                    }]
                },
                metaTags: {
                    title: ['$filter', 'categories', function($filter, categories) {
                        if (categories && categories.length) {
                            return $filter('name')(categories[0].names);
                        }
                    }],
                    description: ['$filter', 'categories', function ($filter, categories) {
                        if (!categories || !categories.length) {
                            return;
                        }

                        for (var i = 0; i < categories.length; i++) {
                            if (categories[i].description) {
                                return categories[i].description;
                            }
                        }

                        return $filter('name')(categories[0].names);
                    }],
                    keywords: ['$filter', 'categories', function ($filter, categories) {
                        if (!categories || !categories.length) {
                            return;
                        }

                        for (var i = 0; i < categories.length; i++) {
                            if (categories[i].keywords) {
                                return categories[i].keywords;
                            }
                        }

                        return $filter('name')(categories[0].names);
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/category-products/index.html',
                        controller: 'CategoryProductsCtrl as categoryCtrl'
                    }
                }
            });
        }])
        .controller('CategoryProductsCtrl', [
            '$scope', '$rootScope', '$filter', '$q', '$state', '$stateParams', '$location', 'Api', 'Util', 'Items',
            'Cart', 'FilterManager', 'User', 'categories', 'CATEGORIES_TYPES', 'ADVERTISEMENT_CONVERSION_TYPE', 'Config', 'DataLayer',
            function ($scope, $rootScope, $filter, $q, $state, $stateParams, $location, api, util, Items,
                      cart, FilterManager, User, categories, CATEGORIES_TYPES, ADVERTISEMENT_CONVERSION_TYPE, Config, DataLayer) {
                var categoryCtrl = this;

                var _isRetailerPremiumReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement;

                angular.extend(categoryCtrl, {
                    products: [],
                    total: 0,
                    openProduct: openProduct,
                    category: categories || {},

                    productTagsFilter: FilterManager.getProductTagsFilter(_onChangeFilters, $scope, false),
                    brandFilter: FilterManager.getBrandsFilter(_onChangeFilters),
                    familiesFilter: FilterManager.getFamiliesFilter(_onChangeFilters),
                    supervisionsFilter: FilterManager.getSupervisionFilter(_onChangeFilters),
                    orderSorter: {
                        namesNgFilter: $filter('translate'),
                        title: 'Order items by',
                        defaultText: 'Select',
                        options: {
                            items: [
                                {
                                    name: 'Price high-low',
                                    field: 'branch.regularPrice',
                                    desc: true
                                },
                                {
                                    name: 'Price low-high',
                                    field: 'branch.regularPrice',
                                    desc: false
                                }
                            ]
                        },
                        onChange: _onChangeFilters
                    },
                    groupBy: {
                        titleStyleClass: 'family-group-title',
                        by: 'family.id',
                        value: 'family',
                        isOneColumn: '(names | name).long.length > 50',
                        templateUrl: 'template/views/category-products/partials/menu-family-group-title/index.html',
                        itemHeightRatio: '(names | name).short != (names | name).long ? 1.5 : 1'
                    }
                });

                categoryCtrl.events = $stateParams.events;
                $location.search('events', null);

                _initCategory();
                _initFilters();

                /**
                 * Initializes the filters and sort values and options
                 * @private
                 */
                function _initFilters() {
                    if (categoryCtrl.category._actualType == CATEGORIES_TYPES.MENU) {
                        _initMenuFilters()
                        return;
                    }

                    categoryCtrl.sorters = [categoryCtrl.orderSorter];
                    categoryCtrl.filters = [categoryCtrl.productTagsFilter, categoryCtrl.familiesFilter, categoryCtrl.brandFilter];
                    $rootScope.config.retailer.allowSupervisionFilter && (categoryCtrl.filters.push(categoryCtrl.supervisionsFilter));

                    FilterManager.setFiltersFromUrl({
                        productTag: categoryCtrl.productTagsFilter,
                        family: categoryCtrl.familiesFilter,
                        brand: categoryCtrl.brandFilter,
                        supervision: categoryCtrl.supervisionsFilter
                    }, $stateParams.filters, categoryCtrl.orderSorter, $stateParams.sort);

                    api.request({
                        url: '/v2/retailers/:rid/branches/:bid/categories/' + categoryCtrl.category.id + '/products/filters'
                    }, {
                        fireAndForgot: true
                    }).then(function (results) {
                        FilterManager.addProductTagsFilterOptions(categoryCtrl.productTagsFilter, results.productTags, true);
                        FilterManager.addBrandsFilterOptions(categoryCtrl.brandFilter, results.brands);
                        FilterManager.addFamiliesFilterOptions(categoryCtrl.familiesFilter, results.families);
                        FilterManager.addSupervisionsFilterOptions(categoryCtrl.supervisionsFilter, results.supervisions);
                    }).finally(function () {
                        FilterManager.markFiltersAsFinished([categoryCtrl.productTagsFilter, categoryCtrl.brandFilter, categoryCtrl.familiesFilter, categoryCtrl.supervisionsFilter]);
                    });
                }

                function _initMenuFilters(){
                    categoryCtrl.sorters = null
                    categoryCtrl.filters = [categoryCtrl.productTagsFilter];
                    FilterManager.setFiltersFromUrl({
                        productTag: categoryCtrl.productTagsFilter,
                    }, $stateParams.filters, null, null);

                    api.request({
                        url: '/v2/retailers/:rid/branches/:bid/categories/' + categoryCtrl.category.id + '/products/filters'
                    }, {
                        fireAndForgot: true
                    }).then(function (results) {
                        FilterManager.addProductTagsFilterOptions(categoryCtrl.productTagsFilter, results.productTags, true);
                    }).finally(function () {
                        FilterManager.markFiltersAsFinished([categoryCtrl.productTagsFilter]);
                    });

                }
                function openProduct(product, index) {
                    if (product.isBumper) {
                        return;
                    }

                    util.openProduct(product, {
                        next: _nextProduct,
                        previous: _previousProduct,
                        siblings: [Items.items[index - 1], Items.items[index - 2], Items.items[index + 1], Items.items[index + 2]],
                        total: categoryCtrl.total,
                        index: index
                    });
                }

                function _onChangeFilters() {
                    FilterManager.setFiltersToUrl({
                        productTag: categoryCtrl.productTagsFilter,
                        family: categoryCtrl.familiesFilter,
                        brand: categoryCtrl.brandFilter,
                        supervisions: categoryCtrl.supervisionsFilter
                    }, categoryCtrl.orderSorter);

                    Items.resetItems();
                    categoryCtrl.products.splice(0, categoryCtrl.products.length);
                    return _addChunkToProducts(true);
                }

                function _addChunkToProducts(isInit) {
                    if ($rootScope.suggestionsDialogOpened) {
                        return;
                    }

                    var totalItems = Items.getItemsLength();
                    if (!isInit && (totalItems == categoryCtrl.total || categoryCtrl.total == categoryCtrl.products.length)) {
                        return;
                    }

                    return Items.getChunkAmount().then(function(size) {
                        var params = {
                            from: totalItems,
                            size: size,
                            languageId: $rootScope.config.language.id,
                            minScore: 0
                        };

                        params.names = [];

                        angular.forEach(categories, function (category) {
                            angular.forEach(category.names, function (value) {
                                params.names.push(value);
                            });
                        });

                        FilterManager.parseFilters(params, [categoryCtrl.productTagsFilter, categoryCtrl.brandFilter, categoryCtrl.familiesFilter, categoryCtrl.supervisionsFilter]);

                        var sort = [],
                            categorySort = _getCategorySort();
                        if (categoryCtrl.category._actualType == CATEGORIES_TYPES.MENU) {
                            sort.push({'family.id': 'asc'});
                            if (!categorySort) {
                                sort.push({'localBarcode.raw': 'asc'});
                            } else {
                                params.categorySort = categorySort;
                            }
                        } else if (categoryCtrl.orderSorter.value) {
                            var paramsSort = {};
                            paramsSort[categoryCtrl.orderSorter.value.field] = categoryCtrl.orderSorter.value.desc ? 'desc' : 'asc';
                            sort = [paramsSort];
                        } else {
                            params.categorySort = categorySort;
                        }

                        if (sort.length) {
                            params.sort = sort;
                        }

                        if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                            var filterJsonShowUnavailableProducts = {
                                "mustNot": {
                                    "term": {
                                        "branch.isOutOfStock": true
                                    }
                                }
                            }
                            if(params.filters) {
                                Object.assign(params.filters,filterJsonShowUnavailableProducts);
                            } else {
                                params.filters = filterJsonShowUnavailableProducts
                            }

                        }
                        else {
                            var filterJson = {
                                "should": [
                                    {
                                        "bool": {
                                            "must_not": {
                                                "exists": {
                                                    "field": "branch.outOfStockShowUntilDate"
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "bool": {
                                            "must": [
                                                {
                                                "range": {
                                                    "branch.outOfStockShowUntilDate": {
                                                    "gt": "now"
                                                    }
                                                }
                                                },
                                                {
                                                "term": {
                                                    "branch.isOutOfStock": true
                                                }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "bool": {
                                            "must": [
                                                {
                                                "term": {
                                                    "branch.isOutOfStock": false
                                                }
                                                }
                                            ]
                                        }
                                    }
                                    ]
                                };
                            if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                                if (params.filters) {
                                    params.filters.bool = filterJson
                                } else {
                                    params.filters = {"bool" : filterJson}
                                }
                            };
                        }

                        return Items.getItems({
                            url: '/v2/retailers/:rid/branches/:bid/categories/' + categoryCtrl.category.id + '/products',
                            params: params
                        });
                    }).then(function (resp) {
                        var currentProductsCount = categoryCtrl.products.length;
                        var chunks = Items.insertBumpersToChunk(cart.getProducts(resp.products))
                        if (_isRetailerPremiumReplacementsEnabled) {
                            var linesForSuggestions = [];
                            
                            angular.forEach(chunks, function (chunk) {

                                if (chunk && chunk.branch && chunk.branch.isOutOfStock) {
                                    linesForSuggestions.push(chunk);
                                }
                            });

                            if (linesForSuggestions.length) {
                                util.setReplacements(linesForSuggestions);
                            }

                        }
                        util.pushAll(categoryCtrl.products, chunks);
                        categoryCtrl.total = resp.total === 0 || resp.total ? resp.total : categoryCtrl.total;
                        util.setProductIndexPosition(resp.products);
                        DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: resp.products, data: {productsStartingPosition: currentProductsCount}});
                        return resp;
                    });
                }

                function _getCategorySort() {
                    var categorySorts = [];

                    var categoryWithSort = categories.find(function(node) {
                        return !!node.categorySort;
                    });
                    if (categoryWithSort && categoryWithSort.categorySort) {
                        angular.forEach(categoryWithSort.categorySort, function(categorySortItem) {
                            categorySorts.push({
                                sortType: categorySortItem.sortType,
								topPriority: categorySortItem.topPriority && (angular.toJson(JSON.parse(categorySortItem.topPriority).map(function(x) {
									return {id: x.id};
								})))
                            });
                        });
                    }

                    var userPurchasesSortBoost = User.getPurchasesSortBoost();
                    if (userPurchasesSortBoost) {
                        categorySorts.push(userPurchasesSortBoost);
                    }

                    var productTagsSortBoost = FilterManager.getProductTagsSortBoost(categoryCtrl.productTagsFilter);
                    if (productTagsSortBoost) {
                        categorySorts.push(productTagsSortBoost);
                    }

                    if (categorySorts.length) {
                        return categorySorts;
                    }
                }

                function _nextProduct(index) {
                    var totalItemsWithoutBumpers = Items.getItemsLength(),
                        nextProductData = Items.getNextProductData(index, 1);
                    if (categoryCtrl.total != totalItemsWithoutBumpers && !nextProductData.product) {
                        return _addChunkToProducts().then(function () {
                            return _nextProduct(index);
                        });
                    }

                    nextProductData.total = Items.items.length + categoryCtrl.total - totalItemsWithoutBumpers;
                    return $q.resolve(nextProductData);
                }

                function _previousProduct(index) {
                    var nextProductData = Items.getNextProductData(index, -1);
                    nextProductData.total = Items.items.length + categoryCtrl.total - Items.getItemsLength();
                    return $q.resolve(nextProductData);
                }

                function _initCategory() {
                    var actualType;
                    if (categories && angular.isArray(categories) && categories.length) {
                        categoryCtrl.category = categories[0];

                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Menu', action: 'Click', label: categoryCtrl.category.names}});

                        //for tests
                        //categoryCtrl.category.type = categoryCtrl.category.id == 78067 ? CATEGORIES_TYPES.MENU : categoryCtrl.category.type;
                        var imageUrl;
                        for (var i = 0; i < categories.length; i++) {
                            if (!imageUrl && !!categories[i].siteImage) {
                                imageUrl = categories[i].siteImage;
                            }
                            if (!actualType && !!categories[i].type && categories[i].type != CATEGORIES_TYPES.INHERIT) {
                                actualType = categories[i].type;
                            }

                            if (imageUrl && actualType) break;
                        }
                        categoryCtrl.category._siteImage = imageUrl || (categories[categories.length - 1].imageUrl || '').replace('normal', 'big');

                        util.setCurrentCategory(categories, $scope);
                    } else {
                        actualType = categoryCtrl.category.type;
                        util.setCurrentCategory(categoryCtrl.category, $scope);
                    }
                    categoryCtrl.category._actualType = actualType || CATEGORIES_TYPES.REGULAR;

                    _checkSubCategoriesType(categoryCtrl.category);
                }

                function _checkSubCategoriesType(category) {
                    if (categoryCtrl.category._actualType != CATEGORIES_TYPES.MENU) return;

                    if (category.type == CATEGORIES_TYPES.REGULAR) {
                        return categoryCtrl.category._actualType = CATEGORIES_TYPES.REGULAR;
                    }

                    angular.forEach(category.subCategories || [], _checkSubCategoriesType);
                }

                /**Listeners*/
                util.currentScopeListener($scope, $rootScope.$on('items.initiated', function () {
                    _addChunkToProducts(true);
                }));

                util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function (event, data) {
                    if (categoryCtrl.products.length && categoryCtrl.events) {
                        util.firePromotionEvent(categoryCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.ADD_TO_CART]);
                        if (data.lines && data.lines.length) {
                            data.lines[0].adConversionUrl = categoryCtrl.events[ADVERTISEMENT_CONVERSION_TYPE.CHARGE];
                        }
                    }
                    angular.forEach(categoryCtrl.products, function (product, index) {
                        if (product.isBumper) return;
                        categoryCtrl.products[index] = cart.getProduct(product);
                    });
                }));

                util.currentScopeListener($scope, $rootScope.$on('document.scroll.end', function (data, event) {
                    event.start();
                    var promise = _addChunkToProducts();
                    if (promise) {
                        promise.finally(function () {
                            event.done();
                        });
                    } else {
                        event.done();
                    }
                }));
                util.currentScopeListener($scope, $rootScope.$on('items.add', function () {
                    _addChunkToProducts();
                }));
            }])

        //those controllers should move to their template
        .controller('PrutahTemplateCategoryProductsCtrl', ['$scope', 'Items', 'CATEGORIES_TYPES', 'SP_ITEMS_DESIGNS', function($scope, Items, CATEGORIES_TYPES, SP_ITEMS_DESIGNS) {
            var categoryCtrl = $scope.categoryCtrl;

            Items.initPage({
                defaultWidth: categoryCtrl.category._actualType === CATEGORIES_TYPES.LARGE_ITEMS ? 370 : 210,
                design: categoryCtrl.category._actualType === CATEGORIES_TYPES.MENU && SP_ITEMS_DESIGNS.MENU,
                isSingleBumper: categoryCtrl.category._actualType === CATEGORIES_TYPES.LARGE_ITEMS
            });
        }])
        .controller('KikarTemplateCategoryProductsCtrl', ['$scope', 'Items', 'CATEGORIES_TYPES', 'SP_ITEMS_DESIGNS', function($scope, Items, CATEGORIES_TYPES, SP_ITEMS_DESIGNS) {
            var categoryCtrl = $scope.categoryCtrl;
            Items.initPage({
                defaultWidth: categoryCtrl.category._actualType === CATEGORIES_TYPES.LARGE_ITEMS ? 350 : 235,
                design: categoryCtrl.category._actualType === CATEGORIES_TYPES.MENU && SP_ITEMS_DESIGNS.MENU,
                isSingleBumper: categoryCtrl.category._actualType === CATEGORIES_TYPES.LARGE_ITEMS
            });
        }]);
})(angular, app);
