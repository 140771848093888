(function (angular, app) {
    'use strict';

    var TIMES_OPTIONS = {
        LOCAL_DELIVERY: 'Local Delivery',
        SHIPPING_SERVICE: 'Shipping'
    };

    var MINUTE_IN_MS = 1000 * 60,
        DAY_IN_MS = MINUTE_IN_MS * 60 * 24;


    app.controller('EditOrderTimeslotCtrl', [
        '$rootScope', '$scope', 'SpDeliveryAreasService', 'Util', 'SpDeliveryTimesService',
        'Config', 'DELIVERY_TIMES_TYPES', '$q', 'SP_SERVICES', '$filter','Orders', 
        function ($rootScope, $scope, SpDeliveryAreasService, util, SpDeliveryTimesService,
            config, DELIVERY_TIMES_TYPES, $q, SP_SERVICES, $filter, Orders
        ) {
            var editOrderTimeslotCtrl = this,
                updateOrderV2Ctrl = $scope.updateOrderV2Ctrl,
                dateFilter = $filter('date'),
                _oldStartDate;

            //properties
            editOrderTimeslotCtrl.checkingInProgress = false;
            editOrderTimeslotCtrl.default = {};
            editOrderTimeslotCtrl.pickerIndex = 0;

            // function
            editOrderTimeslotCtrl.setDeliveryTimes = setDeliveryTimes;
            editOrderTimeslotCtrl.isDisabledUpdateTimeSlot = isDisabledUpdateTimeSlot;

            _init();

            // ----------------------------------------------------
            function _init() {
                return _getOrderInEdit().then(function () {
                    _setOrder(editOrderTimeslotCtrl.order);  
                })
            }
    
            function _getOrderInEdit(){
                var orderPromise;
                if (!Orders.orderInEdit) {
                    orderPromise = Orders.getOrderDetails(updateOrderV2Ctrl.orderId);
                } else {
                    orderPromise = $q.resolve(Orders.orderInEdit);
                }
                return orderPromise.then(function (order) {
                    editOrderTimeslotCtrl.order = order;
                });
            }

            function _setOrder(order) {
                editOrderTimeslotCtrl.areaId = order.branchAreaId ? order.branchAreaId : null;
                editOrderTimeslotCtrl.limitDate = util.calculatePostponeUpdateDateTime(order.timePlaced);
                setDeliveryTimes(order);
            }

            function isDisabledUpdateTimeSlot(){
                return !(updateOrderV2Ctrl.delivery && updateOrderV2Ctrl.delivery.times)
                || updateOrderV2Ctrl.delivery.time.newFrom === editOrderTimeslotCtrl.order.shippingTimeFrom
                || updateOrderV2Ctrl.delivery.time.newTo === editOrderTimeslotCtrl.order.shippingTimeTo;
            }   
            // ----------------------------------------------------
            /**
            * Set delivery times
            * @public
            *
            * @param {Object} [options={}]
            *
            * @returns {Promise}
            */
            function setDeliveryTimes(options) {
                options = options || {};

                var params = {},
                deliveryMethod = options.branchDeliveryTypeId ? { id: options.branchDeliveryTypeId } : {};
                editOrderTimeslotCtrl.deliveryMethod = deliveryMethod;
                params.startDate = options.startDate ? options.startDate : null;
                params.areaId = editOrderTimeslotCtrl.areaId;

                if (editOrderTimeslotCtrl.checkingInProgress && !options.ignoreInProgress) {
                    return $q.reject();
                }

                // getting picking branch address
                SpDeliveryAreasService.getAreas().then(function (areas) {
                    var area = areas.find(function (area) {
                        return area.id === editOrderTimeslotCtrl.areaId
                    });
                   
                    editOrderTimeslotCtrl.pickingAddress = SpDeliveryAreasService.getAreaAddressText(area);
                });

                if (options.scroll) {
                    if (!editOrderTimeslotCtrl.area) {
                        return $q.reject();
                    }

                    if (editOrderTimeslotCtrl.timesOptions && editOrderTimeslotCtrl.timesOptions.length && editOrderTimeslotCtrl.timesOptions[0].picker &&
                        editOrderTimeslotCtrl.timesOptions[0].picker.items && editOrderTimeslotCtrl.timesOptions[0].picker.items.length) {
                        params.startDate = new Date(new Date(editOrderTimeslotCtrl.timesOptions[0].picker.items[editOrderTimeslotCtrl.timesOptions[0].picker.items.length - 1].value.date).getTime() + DAY_IN_MS);

                        if (params.startDate >= editOrderTimeslotCtrl.limitDate) {
                            return $q.reject();
                        }
                    }

                    if (!params.startDate) {
                        return $q.reject();
                    }

                } else {
                    _resetTimesOptions();
                }

                _oldStartDate = params.startDate;
                if (!options.ignoreInProgress) {
                    editOrderTimeslotCtrl.checkingInProgress = true;
                }

                delete editOrderTimeslotCtrl.otherBranchResponse;
                _setFormError('');
                params.maxDisplayDays = editOrderTimeslotCtrl.limitDate;
                return SpDeliveryTimesService.getTimes(deliveryMethod.id, params).then(function (resp) {
                    if (angular.isArray(resp.area.areas) && resp.area.areas.length > 1) {
                        if (options.scroll) {
                            angular.forEach(resp.area.areas, function (area) {
                                _addScrollLocalDeliveryTimes(area, editOrderTimeslotCtrl.areasById[area.id]);
                            });
                        } else {
                            editOrderTimeslotCtrl.areasByName = {};
                            editOrderTimeslotCtrl.areasById = {};
                            editOrderTimeslotCtrl.areasOptions = [];
                            angular.forEach(resp.area.areas, function (area) {
                                editOrderTimeslotCtrl.areasByName[area.name] = editOrderTimeslotCtrl.areasByName[area.name] || [];
                                editOrderTimeslotCtrl.areasByName[area.name].push(area);
                                editOrderTimeslotCtrl.areasById[area.id] = area;
                                editOrderTimeslotCtrl.areasOptions.push({ name: area.name, id: area.id });
                            });
                            _setAreaByConfig();
                            return;
                        }
                    } else if (options.scroll) {
                        _addScrollLocalDeliveryTimes(resp.area, editOrderTimeslotCtrl.area);
                    }

                    if (options.scroll) {
                        return _setTimesOptions(editOrderTimeslotCtrl.area, {
                            localDelivery: editOrderTimeslotCtrl.area.timesObject.times.localDelivery || {}
                        });
                    }
                    editOrderTimeslotCtrl.area = resp.area;
                    updateOrderV2Ctrl.area = resp.area;
                    return _setTimesOptions(resp.area, resp.times, { reset: true });
                }).catch(function (resp) {
                    _resetTimesOptions();
                    switch (resp.statusCode) {
                        case 400:
                            return _setFormError('We didn\'t recognize the address you entered');

                        case 404:
                            return _setFormError('Sorry, currently we do not deliver to this area, for further inquiries call');
                    }

                    if (resp instanceof Error) {
                        console.error(resp);
                    }
                }).finally(function () {
                    if (!options.ignoreInProgress) {
                        editOrderTimeslotCtrl.checkingInProgress = false;
                    }
                });
            }

            function _addScrollLocalDeliveryTimes(fromArea, toArea) {
                toArea.timesObject.times.localDelivery = toArea.timesObject.times.localDelivery || {};
                toArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] = toArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] || [];
                fromArea.timesObject.times.localDelivery = fromArea.timesObject.times.localDelivery || {};
                fromArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] = fromArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR] || [];
                Array.prototype.push.apply(toArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR], fromArea.timesObject.times.localDelivery[DELIVERY_TIMES_TYPES.REGULAR]);
            }

            /**
             * Reset times options
             * @private
            */
            function _resetTimesOptions() {
                editOrderTimeslotCtrl.isDeliveryWithinDaysOnly = false;
                // if (details) {
                //     updateOrderV2Ctrl.delivery = {};
                // }

                var timeOption = {
                    picker: {
                        key: 'times',
                        templateUrl: 'template/views/checkout/partials/delivery-day/index.html',
                        items: _getEmptyDeliveryDays().map(function (day) {
                            return {
                                templateUrl: 'template/views/checkout/partials/delivery-time-dropdown-disabled/index.html',
                                value: day,
                                deliveryMethod: editOrderTimeslotCtrl.deliveryMethod,
                                dropDown: {
                                    items: []
                                }
                            };
                        })
                    }
                };

                //on this to stay there if went to summary page and got back to details page
                editOrderTimeslotCtrl.timesOptions = [timeOption];
                editOrderTimeslotCtrl.timesOption = timeOption;
                //== go back to the first week when resetting
                $rootScope.$emit('picker.backToStart');

                onTimesOptionChange();
            }

            /**
                 * Set form error message
                 * @private
                 *
                 * @param {String} [message]
                 */
            function _setFormError(message, isNotSave) {
                if (message && !isNotSave) {
                    editOrderTimeslotCtrl.formErrorMessage = message;
                }
            }

            function _setAreaByConfig() {
                var configArea = config.getBranchArea();
                if (configArea && configArea.areasByName && configArea.areasByName[configArea.name]) {
                    editOrderTimeslotCtrl.areaQuery = configArea.name;
                }
            }


            /**
             * Set the times options by the given times result
             * @private
             *
             * @param {Object} area
             * @param {Object} times
             * @param {Array<Object>} times.localDelivery
             * @param {Array<Object>} times.shipping
             * @param {Object} [options]
             * @param {boolean} [options.reset]
             *
             * @return {Promise<void>}
             */
            function _setTimesOptions(area, times, options) {
                return $q.resolve().then(function () {
                    if (!times.localDelivery) {
                        return;
                    }
                    return _getLocalDeliveryTimesOption(area, times.localDelivery, options);
                }).then(function (localDeliveryTimesOption) {
                    if (options && options.reset) {
                        editOrderTimeslotCtrl.timesOptions = [];
                        if (localDeliveryTimesOption) {
                            editOrderTimeslotCtrl.timesOptions.push(localDeliveryTimesOption);
                        }
                        if (times.shipping) {
                            editOrderTimeslotCtrl.timesOptions.push(_getShippingTimes(times.shipping));
                        }
                        editOrderTimeslotCtrl.timesOption = editOrderTimeslotCtrl.timesOptions[0];
                    } else {
                        var localDeliveryCurrentOption = _findLocalDeliveryTimesOption();
                        if (localDeliveryCurrentOption) {
                            editOrderTimeslotCtrl.timesOptions.splice(editOrderTimeslotCtrl.timesOptions.indexOf(localDeliveryCurrentOption), 1);
                        }
                        editOrderTimeslotCtrl.timesOptions.unshift(localDeliveryTimesOption);
                        editOrderTimeslotCtrl.timesOption = localDeliveryTimesOption;
                    }
                    onTimesOptionChange();
                })
            }

            /**
             * Get the local delivery times option by the given local delivery times
             * @private
             *
             * @param {Object} area
             * @param {Array<Object>} times
             * @param {Object} [options]
             * @param {boolean} [options.reset]
             *
             * @returns {Promise<{label: string, picker: Object}>}
             */
            function _getLocalDeliveryTimesOption(area, times, options) {
                return SpDeliveryTimesService.isDeliveryWithinDaysOnly({
                    deliveryTimesObj: times,
                    isDeliveryWithinDaysByTag: config.retailer.isDeliveryWithinDaysByTag,
                    retailerId: config.retailer.id,
                    areaId: area.id
                }).then(function (isDeliveryWithinDaysOnly) {
                    options = options || {};
                    var picker = {
                        key: 'times',
                        templateUrl: 'template/views/checkout/partials/delivery-day/index.html',
                        items: [],
                        deliveryMethod: editOrderTimeslotCtrl.deliveryMethod
                    };

                    editOrderTimeslotCtrl.isDeliveryWithinDaysOnly = isDeliveryWithinDaysOnly;

                    var indexPicker = 0;
                    angular.forEach(times[DELIVERY_TIMES_TYPES.REGULAR] || (options.reset ? _getEmptyDeliveryDays() : []), function (dayTimes) {
                        if (new Date(dayTimes.date).getTime() > editOrderTimeslotCtrl.limitDate) {
                            return;
                        }

                        var item = {
                            templateUrl: 'template/views/checkout/partials/delivery-time-dropdown' + (dayTimes.times.length ? '' : '-disabled') + '/index.html',
                            value: dayTimes,
                            dropDown: {
                                key: 'time',
                                default: 0,
                                items: []
                            }
                        };

                        var indexDropDown = 0;
                        if (dayTimes.times.length) {
                            var firstFromTime;
                            item.dropDown.withSpecials = false;
                            angular.forEach(dayTimes.times, function (time, index) {
                                if (time.newFrom == editOrderTimeslotCtrl.order.shippingTimeFrom && time.newTo == editOrderTimeslotCtrl.order.shippingTimeTo
                                    && time.fromDisplay == editOrderTimeslotCtrl.order.shippingTimeFromDisplay
                                ) {
                                    editOrderTimeslotCtrl.default = {
                                        time: time,
                                        index: indexPicker,
                                        indexDropDown: indexDropDown,
                                        dayTimes: dayTimes
                                    };
                                }
                                item.dropDown.items.push(_getPickerTimeItem(time, dayTimes, area));

                                if (!firstFromTime || firstFromTime > time.from) {
                                    firstFromTime = time.from;
                                    item.dropDown.default = index;
                                }

                                if (time.deliveryProduct && time.deliveryProduct.branch && time.deliveryProduct.branch.specials && time.deliveryProduct.branch.specials.length) {
                                    item.dropDown.withSpecials = true;
                                }
                                indexDropDown++;
                            });
                        } else {
                            item.disabled = true;
                        }
                        indexPicker++;
                        if (editOrderTimeslotCtrl.default && editOrderTimeslotCtrl.default.dayTimes
                            && editOrderTimeslotCtrl.default.dayTimes === dayTimes
                        ) {
                            editOrderTimeslotCtrl.default.content = item;
                        }
                        picker.items.push(item);
                    });

                    return {
                        label: TIMES_OPTIONS.LOCAL_DELIVERY,
                        picker: picker
                    };
                });
            }

            /**
                 * Get shipping times
                 * @private
                 *
                 * @param {Array} times
                 *
                 * @returns {{label: {String}, picker: {Object}}}
                 */
            function _getShippingTimes(times) {
                var picker = {
                    key: 'time',
                    templateUrl: 'template/views/checkout/partials/shipping-day/index.html',
                    items: []
                };

                angular.forEach(times, function (time) {
                    picker.items.push({
                        value: time,
                        price: time.price
                    });

                    if (updateOrderV2Ctrl.delivery && updateOrderV2Ctrl.delivery.time && updateOrderV2Ctrl.delivery.time.serviceCode === time.serviceCode) {
                        updateOrderV2Ctrl.delivery.time = time;
                    }
                });

                return {
                    label: TIMES_OPTIONS.SHIPPING_SERVICE,
                    picker: picker
                };
            }

            function _getEmptyDeliveryDays() {
                return SpDeliveryTimesService.timesObject(null, null, config.retailer.deliveryCustomerDisplayDays)[DELIVERY_TIMES_TYPES.REGULAR];
            }

            function onTimesOptionChange() {
                editOrderTimeslotCtrl.isShippingService = editOrderTimeslotCtrl.timesOption &&
                    editOrderTimeslotCtrl.timesOption.label === TIMES_OPTIONS.SHIPPING_SERVICE;
            }

            function _findLocalDeliveryTimesOption() {
                return editOrderTimeslotCtrl.timesOptions.find(function (timesOption) {
                    return timesOption.label === TIMES_OPTIONS.LOCAL_DELIVERY;
                });
            }

            function _getPickerTimeItem(time, times, area) {
                if (updateOrderV2Ctrl.delivery && updateOrderV2Ctrl.delivery.time && updateOrderV2Ctrl.delivery.time.id === time.id) {
                    updateOrderV2Ctrl.delivery.time = time;
                    updateOrderV2Ctrl.delivery.times = times;
                }

                var timeFormat = config.isUs ? 'hh:mm a' : 'HH:mm',
                    label;
                if (config.retailer.twoHourLaw && editOrderTimeslotCtrl.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY) {
                    label = dateFilter((time.fromDisplay || time.from), timeFormat, 'UTC');
                } else {
                    label = dateFilter(time.from, timeFormat, 'UTC') + '-' + dateFilter(time.to, timeFormat, 'UTC');
                }

                return {
                    from: time.from,
                    to: time.to,
                    label: label,
                    value: time,
                    price: time.deliveryTimePrice || area.deliveryAreaPrice || 0,
                    specials:
                        time.deliveryProduct && time.deliveryProduct.branch && time.deliveryProduct.branch.specials ?
                            time.deliveryProduct.branch.specials.filter(function (special) {
                                return config.retailer.settings.showCouponsSpecials || !special.isCoupon;
                            }) : null
                };
            }

        }]);
})(angular, app);
