(function (angular, app) {
    'use strict';

    app.config([
        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS',
        function(SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.SPECIAL,
                dataQueryParams: SP_URL_DIALOG_DATA_QUERY_PARAMS.SPECIAL,
                canShow: ['paramValue', function(paramValue) {
                    return !isNaN(paramValue)
                }],
                show: [
                    'Dialog', 'SpDialogUrlManager', 'value', 'params',
                    function(Dialog, SpDialogUrlManager, value, params) {
                        return Dialog.show({
                            templateUrl: 'template/dialogs/special-details/index.html',
                            controller: 'SpecialDetailsCtrl as specialDetailsCtrl',
                            styleClass: 'special-details',
                            resolve: {
                                specialId: value,
                                showProductsFrom: params.showFrom
                            },
                            bypass: true,
                            hide: SpDialogUrlManager.backClose,
                            ariaLabelledby: 'special_details_title'
                        });
                    }
                ]
            })
        } 
    ])

    app.controller('SpecialDetailsCtrl', [
        '$rootScope', '$scope', '$timeout', 'Api', 'Cart', 'Util', 'specialId', 'showProductsFrom', 'DataLayer', 'Config',
        function ($rootScope, $scope, $timeout, Api, Cart, Util, specialId, showProductsFrom, DataLayer, Config) {
            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Dialog', action: 'Open', label: 'Special Details Dialog'}});

            var specialDetailsCtrl = this,
                isCartUpdatedForFirstTime = false;
            var _isRetailerPremiumReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement;

            specialDetailsCtrl.togglePurchase = togglePurchase;

            specialDetailsCtrl.endlessScrollOptions = {
                load: getProductsChunk
            };
            specialDetailsCtrl.products = {
                total: null,
                products: []
            };
            specialDetailsCtrl.gifts = {
                total: null,
                products: []
            };

            specialDetailsCtrl.currentPurchase = 0;

            // get first chunk
            getProductsChunk();

            function getProductsChunk() {
                if (specialDetailsCtrl.products.products.length === specialDetailsCtrl.products.total) {
                    return;
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/specials/' + specialId,
                    params: {
                        from: specialDetailsCtrl.products.products.length,
                        size: 20,
                        showProductsFrom: showProductsFrom
                    }
                }).then(function (response) {
                    if (specialDetailsCtrl.products.total === null) {
                        _focusFirstItem();
                    }
                    if (_isRetailerPremiumReplacementsEnabled) {
                        var linesForSuggestions = [];
                        
                        angular.forEach(response.products.products, function (chunk) {

                            if (chunk && chunk.branch && chunk.branch.isOutOfStock) {
                                linesForSuggestions.push(chunk);
                            }
                        });

                        if (linesForSuggestions.length) {
                            Util.setReplacements(linesForSuggestions);
                        }

                    }
                    specialDetailsCtrl.data = response;
                    _setSpecialData();
                    _setSpecialInfo();
                    _calculatePurchaseRemainder();
                });
            }

            function _focusFirstItem() {
                $timeout(function () {
                    if (!$scope.$$destroyed) {
                        angular.element(document.querySelector('.dialog.special-details sp-product')).addClass('hover-mode');
                    }
                }, 0, false);
            }

            function _setSpecialData() {
                specialDetailsCtrl.special = specialDetailsCtrl.data.special;
                specialDetailsCtrl.products.total = specialDetailsCtrl.data.products.total;
                if(!specialDetailsCtrl.products.products){
                    specialDetailsCtrl.products.products = [];
                }
                Array.prototype.push.apply(specialDetailsCtrl.products.products, Cart.getProducts(specialDetailsCtrl.data.products.products));
                if (specialDetailsCtrl.data.gifts) {
                    specialDetailsCtrl.gifts.total = specialDetailsCtrl.data.gifts.total;
                    specialDetailsCtrl.gifts.products = [];
                    Array.prototype.push.apply(specialDetailsCtrl.gifts.products, Cart.getProducts(specialDetailsCtrl.data.gifts.products));
                }
                Util.setProductIndexPosition(specialDetailsCtrl.data.products.products);

                DataLayer.push(DataLayer.EVENTS.VIEW_PROMOTION, {promotion: {id: specialDetailsCtrl.data.special.id, name: specialDetailsCtrl.data.special.description, image: specialDetailsCtrl.data.special.frontendImageUrl}, data: {type: null}});
                DataLayer.push(DataLayer.EVENTS.VIEW_ITEM_LIST, {products: specialDetailsCtrl.data.products.products});
            }

            function _setSpecialInfo(skipRemindersValidation) {
                if (specialDetailsCtrl.special.hasMultipleBuckets) {
                    return;
                }

                var specialProductIds = _getSpecialProductIds(specialDetailsCtrl.products.products, specialDetailsCtrl.gifts.products);

                var specialPurchaseProductIds;
                if (specialDetailsCtrl.gifts.total) {
                    specialPurchaseProductIds = _getSpecialProductIds(specialDetailsCtrl.products.products, []);
                }

                specialDetailsCtrl.quantity = 0;
                specialDetailsCtrl.purchaseQuantity = 0;
                specialDetailsCtrl.total = 0;

                var lines = Cart.getLines(specialDetailsCtrl.products.products).filter(function(line) {
                    return specialProductIds.includes(line.product.id);
                });

                specialDetailsCtrl.showUnit = lines.every(function(line) { return Cart.isUnitsWeighable(line); });

                var productsSameType = specialDetailsCtrl.showUnit || lines.every(function(line) { return !line.product.isWeighable; });

                lines.forEach(function(line) {
                    if (specialProductIds.includes(line.product.id)) {
                        if (productsSameType) {
                            specialDetailsCtrl.quantity += line.quantity;
                        }
                        specialDetailsCtrl.total += line.totalPriceForView - (line.totalGiftsForView || 0);
                    }

                    if (!!specialPurchaseProductIds && specialPurchaseProductIds.length && specialPurchaseProductIds.includes(line.product.id)) {
                        specialDetailsCtrl.purchaseQuantity += line.quantity;
                    }
                });

                if (!skipRemindersValidation && Cart.specialReminders && Cart.specialReminders.inCart && Cart.specialReminders.onProducts) {
                    var remainderInCart = Cart.specialReminders && Cart.specialReminders.inCart && Cart.specialReminders.inCart[specialDetailsCtrl.special.id];
                    var remainderOnProducts = Cart.specialReminders && Cart.specialReminders.onProducts && Cart.specialReminders.onProducts[specialDetailsCtrl.special.id];
                    if((remainderInCart && !remainderInCart.isUsedRemainder && remainderInCart.awarded && remainderInCart.isBuyAndGetType) ||
                        (remainderOnProducts && !remainderOnProducts.isUsedRemainder && remainderOnProducts.awarded && remainderOnProducts.isBuyAndGetType)) {
                        specialDetailsCtrl.showGifts = true;
                    }
                }
            }

            function _calculatePurchaseRemainder() {
                if (!specialDetailsCtrl.special.hasMultipleBuckets) {
                    return;
                }

                specialDetailsCtrl.quantity = 0;
                specialDetailsCtrl.total = 0;
                specialDetailsCtrl.isGiftAbsolute = false;
                specialDetailsCtrl.isTotalPurchaseEnoughForGift = false;

                (specialDetailsCtrl.special.levels || []).forEach(function (level) {
                    (level.gifts || []).forEach(function(gift){
                        specialDetailsCtrl.isGiftAbsolute = gift.type === 1 || specialDetailsCtrl.isGiftAbsolute;
                    });
                    (level.purchases || []).forEach(function (purchase, index) {
                        specialDetailsCtrl.specilaTotalItems += purchase.total;
                        if (purchase.esProducts && purchase.esProducts.length) {
                            var esProducts = purchase.esProducts;
                            purchase.esProducts = [];
                            Array.prototype.push.apply(purchase.esProducts, Cart.getProducts(esProducts));
                        } else {
                            specialDetailsCtrl.specialInactive = true;
                        }
                        var purchaseProductIds = _getSpecialProductIds(purchase.esProducts || [], []),
                            lines = _getSpecialCartLines(purchaseProductIds);

                        purchase.showUnit = _showUnit(lines);
                        var productsSameType = purchase.showUnit || _productsSameType(lines);
                        purchase.quantity = 0;
                        lines.forEach(function(line) {
                            if (purchaseProductIds.includes(line.product.id)) {
                                if (productsSameType) {
                                    purchase.quantity += line.quantity;
                                }
                                specialDetailsCtrl.total += line.totalPriceForView - (line.totalGiftsForView || 0);
                            }
                        });

                        purchase.remainder = (purchase.quantity >= 1 && purchase.quantity < purchase.total) ? purchase.total - purchase.quantity : null
                        specialDetailsCtrl.quantity += purchase.quantity;
                        specialDetailsCtrl.isTotalPurchaseEnoughForGift = (purchase.type === 1 && purchase.total <= specialDetailsCtrl.quantity) 
                                                                        || (purchase.type === 2 && purchase.total <= specialDetailsCtrl.total) || specialDetailsCtrl.isTotalPurchaseEnoughForGift;
                        if (specialDetailsCtrl.currentPurchase === index && !purchase.remainder && !!purchase.quantity) {
                            var nextIndex = level.purchases.length - 1 === index ? index : index + 1;
                            togglePurchase(nextIndex, level.purchases[nextIndex].id);
                        }
                    });
                });
            }

            function _showUnit(lines) {
                return lines.every(function(line) { return Cart.isUnitsWeighable(line); });
            }

            function _productsSameType(lines) {
                return lines.every(function(line) { return !line.product.isWeighable; });
            }

            function _getSpecialProductIds(products, giftProducts) {
                var productsList = products.concat(giftProducts);
                return productsList.map(function(product) {
                    return product.id;
                });
            }

            function _getSpecialCartLines(specialProductIds) {
                return Cart.getLines(specialDetailsCtrl.products.products).filter(function(line) {
                    return specialProductIds.includes(line.product.id);
                });
            }

            function togglePurchase(index, purchaseId) {
                specialDetailsCtrl.currentPurchase = specialDetailsCtrl.currentPurchase === index ? null : index;
                specialDetailsCtrl.currentPurchaseId = (!!specialDetailsCtrl.currentPurchase || specialDetailsCtrl.currentPurchase === 0) ? purchaseId: null;
            }

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Add Items from Special Details Dialog'}});
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function () {
                if (!specialDetailsCtrl.special) return;
                _setSpecialInfo(true);
                _calculatePurchaseRemainder();
                if (!isCartUpdatedForFirstTime) {
                    isCartUpdatedForFirstTime = true;
                    $timeout(function () {
                        specialDetailsCtrl.products.products = [];
                        _setSpecialData();
                    }, 1000)
                }
            }));
        }
    ]);
})(angular, app);
