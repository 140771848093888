(function (angular, app) {
  'use strict';

  /**
   * @typedef {Object} AddressDetail
   * @property {number} placeId
   * @property {string} text1
   * @property {string} city
   * @property {string} country
   * @property {number} lat
   * @property {number} lng
   */

  app.controller('GoogleMapDialogCtrl', ['Dialog', '$timeout', '$scope', 'GoogleMapService', 'LocationService', 'Toast', 'defaultLocation',
    function (Dialog, $timeout, $scope, GoogleMapService, LocationService, Toast, defaultLocation) {
      var googleMapDialogCtrl = this;
      var _DEFAULT_ZOOM = 18;
      var POPUP_MARKER_UI = '<div><svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none"><use xlink:href="#sp-icons-small-gg-map-marker"></use></svg><p>{formattedAddress}</p></div>';

      angular.extend(googleMapDialogCtrl, {
        markerPopup: null,
        addressDetail: {
          city: '',
          text1: '',
          zipCode: '',
          country: '',
          geoCoordinates: null,
          externalPlaceId: null,
          hasHouseNumberAndRoute: true,
        },
        formattedAddress: '',
        mapErrorMessage: '',
        defaultLocation: defaultLocation,
        handleOnSaveSelectedAddress: handleOnSaveSelectedAddress
      });

      _init();

      //================== PRIVATES FUNCTIONS  ==================//

      function _setAddress(geocode) {
        var extractedInfo = GoogleMapService.extractInfoFromAddressComponents(geocode.address_components);
        var text1Obj = GoogleMapService.constructText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city);
        googleMapDialogCtrl.formattedAddress = geocode.formatted_address;
        angular.extend(googleMapDialogCtrl.addressDetail, {
          text1: text1Obj.value,
          city: extractedInfo.city,
          country: extractedInfo.country,
          zipCode: extractedInfo.zipCode,
          state: extractedInfo.state,
          geoCoordinates: {
            lat: geocode.geometry.location.lat(),
            lng: geocode.geometry.location.lng(),
          },
          externalPlaceId: geocode.place_id,
          hasHouseNumberAndRoute: text1Obj.hasHouseNumberAndRoute
        });
        $scope.$applyAsync();
      }

      /**
       * @param {GeoLocation} 
       * @returns {google.maps.Map}
       */
      function _initMap(defaultLocation) {
        var mapRef = document.getElementById('map');
        if (!mapRef) {
          return;
        }
        var map = GoogleMapService.initMap(mapRef, {
          zoom: _DEFAULT_ZOOM,
          center: defaultLocation,
        });
        return map;
      }

      /**
       * @param {GeoLocation} location refer service/google-map
       */
      function _setAddressFromLocation(location) {
        GoogleMapService.reverseGeocode({ location: location}).then(function (geocode) {
          _setAddress(geocode);
        });
      }

      /**
       * @param {google.maps.Map} map 
       * @param {{lat: number, lng: number}} defaultLocation 
       * @returns {google.maps.Marker}
       */
      function _initMarker(map, location) {
        var marker = GoogleMapService.addMarker(map, location);
        return marker;
      }

      /**
       * @param {google.maps.Map} map 
       * @param {google.maps.Marker} marker 
       */
      function _addMarkerEventListener(map, marker) {
        GoogleMapService.addDragMarkerEventLister(marker, _setAddress);
        GoogleMapService.addClickMapMoveMarkerEventListener(marker, map, _setAddress);
        googleMapDialogCtrl.markerPopup = GoogleMapService.addPopupOnClickingMarker(map, marker, null);
      }

      /**
       * @param {GeoLocation} location
       * @param {google.maps.Map} map
       */
      function _initAutoComplete(location, map, marker) {
        var inputRef = document.getElementById('pac-input');
        if (!inputRef) {
          return;
        }
        var autocomplete = GoogleMapService.initAutoComplete(inputRef, location, map);
        GoogleMapService.addAutocompleteChangeEventListener(autocomplete, map, marker, _setAddress);
      }

      function _init() {
        LocationService.getUserLocation().then(function (userLocation) {
          if (userLocation) {
            return userLocation;
          }
          
          if(googleMapDialogCtrl.defaultLocation){
            return GoogleMapService.reverseGeocode({ address: googleMapDialogCtrl.defaultLocation}).then(function (res){
              if(!res) return;

              return {
                lat: res.geometry.location.lat(),
                lng: res.geometry.location.lng()
              }
            }).catch(function () { return; });
          }

          return;
        }).then(function (location) {
          if (location) {
            return location;
          }
          
          var branchLocation = LocationService.getBranchLocation();
          if (branchLocation.lat && branchLocation.lng) {
            return branchLocation;
          }
          return;
        }).then(function (location) {
          if (!location) {
            throw new Error(_translate("gg_map_alert_need_location"));
          }

          var map = _initMap(location);
          $timeout(function () {
            var marker = _initMarker(map, location);
            _setAddressFromLocation(location);
            _addMarkerEventListener(map, marker);
            _initAutoComplete(location, map, marker);
          }, 300);
        }).catch(function (error) {
          googleMapDialogCtrl.mapErrorMessage = error.message;
          Toast.show({
            timeout: 5000,
            content: googleMapDialogCtrl.mapErrorMessage,
          });
        });
      }

      //================== PUBLIC FUNCTIONS  ==================//
      function handleOnSaveSelectedAddress() {
        Dialog.hide(googleMapDialogCtrl.addressDetail);
      }

      //================== SCOPE LISTENER  ==================//
      $scope.$watch('googleMapDialogCtrl.formattedAddress', function () {
        var popupContent = POPUP_MARKER_UI.replace('{formattedAddress}', googleMapDialogCtrl.formattedAddress);
        if (googleMapDialogCtrl.markerPopup) {
          googleMapDialogCtrl.markerPopup.setContent(popupContent);
        }
      });
    }
  ]);
})(angular, app);
