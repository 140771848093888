(function (angular, app) {
    'use strict';

    app.directive('spProduct', ['Util', function (Util) {
        return {
            restrict: 'E',
            scope: {
                size: '@',
                text: '@',
                hideCart: '@',
                hideSales: '@',
                product: '=',
                showItemDescription: '<',
                boostProductTags: '<?',
                openProduct: '&?',
            },
            controller: ['$scope', '$rootScope', 'Config','User', 'Cart', function ($scope, $rootScope, Config, User, Cart) {
                $scope.productSpecials = Util.filterProductSpecials($scope.product.branch);
                $scope.hasClubSpecial = Util.productHasClubSpecial($scope.product.branch);
                $scope.getLine = getLine;

                var _isRetailerPremiumReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement;
                
                function getLine() {
                    return Util.getProductCartLine($scope.product, $scope.product.isCaseMode);
                }

                $scope.showReplacementLabel = false;
                
                if (_isRetailerPremiumReplacementsEnabled) {
                    $rootScope.$on('cart.update.complete', function() {
                        Util.setReplacement($scope)
                    })
                    $rootScope.$on('loginForAnalyticServiceProvider', function() {
                        Util.setReplacement($scope)
                    })
                }
            }],
            templateUrl: 'template/directives/sp-product/index.html',
            link: function ($scope, $element) {
                $element.attr('itemscope', '');
                $element.attr('itemtype', 'http://schema.org/Product');
                $element.attr('role', 'none');
                $element.bind('focus', function () {
                    $element.on('mouseover', function () {
                        $element.addClass('mouse-hover');
                    });

                    $element.on('mouseout', function () {
                        $element.removeClass('mouse-hover');
                    });
                });
            }
        };
    }]);
})(angular, app);