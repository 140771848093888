(function (angular, app) {
    'use strict';

    var PRODUCT_SUGGESTIONS_FILTERS = {
        must: {
            exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
            term: {
                'branch.isActive': true,
                'branch.isVisible': true,
                isSuggested: true
            }
        },
        mustNot: {
            term: {
                'branch.regularPrice': 0
            }
        }
    };

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.cartSummary', {
                url: '/cart/summary',
                data: {
                    routeAccess: [ROUTE_ACCESS.NOT_EMPTY_CART_ACTUAL_LINES, ROUTE_ACCESS.LOGIN],
                    name: 'cart-summary',
                    layout: {
                        sidenav: false
                    }
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Summary'));
                    }]
                },
                resolve: {
                    previousStateName: ['$state', function ($state) {
                        return $state.$current.path[1] && $state.$current.path[1].self.name;
                    }],
                    lastMinuteRecommendations: ['$state', 'Api', 'User', 'Cart', function ($state, Api, User, Cart) {
                        if (!$state.$current.path[1] || $state.$current.path[1].self.name !== 'app.cartSummary') {
                            return Api.request({
                                method: 'GET',
                                url: '/v2/retailers/:rid/branches/:bid/last-minute-recommendations'
                            });
                        }
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/cart-summary/index.html',
                        controller: 'CartSummaryCtrl as cartSummaryCtrl'
                    }
                }
            });
        }])
        .controller('CartSummaryCtrl', [
            '$scope', '$rootScope', '$state', '$q', '$timeout', '$element', 'Config', 'Api', 'Dialog', 'Util', 'Cart', 'LocalStorage',
            'Products', 'Specials', 'ShippingTermsPopup', 'ShopListService', 'User', 'Cart', 'PermanentFilters', 'SP_SERVICES',
            'ADVERTISEMENT_AD_TYPE', 'ADVERTISEMENT_CONVERSION_TYPE', 'ADVERTISEMENT_GROUP_TYPE', 'previousStateName', 'lastMinuteRecommendations', 'DataLayer',
            function ($scope, $rootScope, $state, $q, $timeout, $element, Config, Api, dialog, Util, Cart, localStorage, Products, Specials,
                      ShippingTermsPopup, shopListService, User, cart, PermanentFilters, SP_SERVICES,
                      ADVERTISEMENT_AD_TYPE, ADVERTISEMENT_CONVERSION_TYPE, ADVERTISEMENT_GROUP_TYPE, previousStateName, lastMinuteRecommendations, DataLayer) {
                var cartSummaryCtrl = this,
                    _advertisementsPromises = {},
                    LOYALTY_PREMIUM_PACKAGE = 16,
                    _isRetailerPremiumReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement;

                angular.extend(cartSummaryCtrl, {
                    radioOptions: [{value: 'category',label: 'Category'},{value: 'order',label: 'Order'}],
                    isProductSuggestionsActive: Config.retailer.isProductSuggestionsActive,
                    suggestionsCarouselFunctions: {},
                    coupon: {},
                    LMRSettings: lastMinuteRecommendations.settings,
                    sections: [],

                    clearCart: clearCart,
                    proceedToCheckout: proceedToCheckout,
                    tryLogin: tryLogin,
                    createNewShopList: createNewShopList,
                    saveToShopList: saveToShopList,
                    addCouponToCart: addCouponToCart,
                    toggleProductsSuggestions: toggleProductsSuggestions,
                    loadProductSuggestions: loadProductSuggestions,
                    addPromotionToUser: addPromotionToUser,
                    trapProductsSuggestionsFocus: trapProductsSuggestionsFocus,
                    productSuggestionsCarousel: {
                        'cart.lines.add': function (products) {
                            angular.forEach(cart.getProducts(products), function (cartProduct, index) {
                                if (cartProduct !== products[index]) {
                                    products.splice(index, 1, cartProduct);
                                }
                            });
                        }
                    }
                });
                cartSummaryCtrl.radioValue = cartSummaryCtrl.radioOptions[0].value;
                angular.element(document.querySelector('html')).removeClass('sidenav-history-order-shown');

                
                cartSummaryCtrl.showLastMinuteRecommendations = 
                    lastMinuteRecommendations.settings && lastMinuteRecommendations.settings.config &&
                    lastMinuteRecommendations.settings.config.areLastMinuteRecommendationsEnabled === 'true';
                if (cartSummaryCtrl.showLastMinuteRecommendations) {
                    Config.initPromise.then(function() {
                        var area = Config.getBranchArea();
                        var sectionsToFilter = [$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_COMPLEMENTARY, $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.OFFERS, $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_USUALLY];
                        var allowedDeliveryTypes = [$rootScope.SP_SERVICES.DELIVERY_TYPES.DELIVERY, $rootScope.SP_SERVICES.DELIVERY_TYPES.EXPRESS_DELIVERY];
                        var productsToFilter = new Set();

                        if (lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION] && lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION].length > 0) {
                            lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION].forEach(function (product) {
                                productsToFilter.add(product.id);
                            });
                        }

                        lastMinuteRecommendations.settings.sections.forEach(function(section) {
                            if (area && !allowedDeliveryTypes.includes(area.deliveryTypeId) && lastMinuteRecommendations.settings.config.hideTipsCarouselOnPickUp === 'true') {
                                if (section.sectionType === $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION) {
                                    return;
                                }
                                else if (sectionsToFilter.includes(section.sectionType) && productsToFilter.size > 0) {
                                    lastMinuteRecommendations.data[section.sectionType] = lastMinuteRecommendations.data[section.sectionType].filter(function (product) {
                                        return !productsToFilter.has(product.id)
                                    });
                                }
                            }

                            cartSummaryCtrl.sections.push(new LastMinuteRecommendationsSections(section, lastMinuteRecommendations));
                        });
                    });

                    User.getData().then(function(user) {
                        cartSummaryCtrl.user = user;
                    });

                    $element.parent().addClass('split-view');
                    if($rootScope.config.retailer.promotion && $rootScope.config.retailer.allowCoupons) {
                        $element.parent().addClass('two-lines');
                    }
                }
                cartSummaryCtrl.isLoyaltyPackageEnabled = Config.retailer.premiumFeaturesEnabled.includes(LOYALTY_PREMIUM_PACKAGE);
                cartSummaryCtrl.userCashbackLoyalty = User.data.loyaltyClubs && User.data.loyaltyClubs.length && User.data.loyaltyClubs.find(function(club) {
                    return _isCashbackLoyaltyClub(club.loyaltyClubId);
                });
                if(cartSummaryCtrl.userCashbackLoyalty) {
                    _setCashbackLoyaltyClub(cartSummaryCtrl.userCashbackLoyalty.loyaltyClubId);
                }

                if(cartSummaryCtrl.activeCashbackLoyalty) {
                    Util.getUserCashbackPoints(cartSummaryCtrl.userCashbackLoyalty.id).then(function (response) {
                        cartSummaryCtrl.userCashbackLoyalty.points = response.points || 0;
                    });
                }

                _setShopLists();
                _initAdvertisements();

                User.getData().then(function(userData){
                    cartSummaryCtrl.userConnected = !!userData;
                    cartSummaryCtrl.userLoyaltyClubCardId  = userData.loyaltyClubCardId;
                    cartSummaryCtrl.userLoyaltyVerificationStatus = userData.loyaltyVerificationStatus;
                    cartSummaryCtrl.userPersonalMinOrderLimit = userData.personalMinOrderLimit;
                });

                ShippingTermsPopup.showWithRedirect();

                cart.forceUpdate().then(function() {
                    var cartLines = cart.getLines();
                    var cartLineProducts = [];

                    if(cartLines && Array.isArray(cartLines) && cartLines.length) {
                        angular.forEach(cartLines, function (cartLine) {
                            if(cartLine && cartLine.product && cartLine.product.id) {
                                cartLine.product.quantity = cartLine.quantity;
                                cartLineProducts.push(cartLine.product);
                            }
                        });
                    }

                    DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 1, option: 'cart summary'}});
                });

                function clearCart() {
                    Util.openClearCartDialog(cart);
                }

                function proceedToCheckout() {
                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLines([]);
                    if (isLimitPassed === false) {
                        return cart.checkUserCreditLimited(false, '').then(function(isLimited) {
                            if (isLimited) {
                                return $q.reject('creditLimitExceeded');
                            }

                            return Config.initPromise;
                        }).then(function() {
                            return Util.validateLoyaltyExpirationDate();
                        }).then(function() {
                            if (cart.outOfStockLines && cart.outOfStockLines.length) {
                                return Util.openOutOfStockDialog(cart.outOfStockLines).then(function(result) {
                                    return !result || !result.isConfirmed ? $q.reject('Out of stock not confirmed') : Config.initPromise;
                                });
                            }
                            return Config.initPromise;
                        }).then(function() {
                            var area = _getAreaById(Config.branchAreaId),
                                minimumOrderPrice = (!!cartSummaryCtrl.userPersonalMinOrderLimit || cartSummaryCtrl.userPersonalMinOrderLimit === 0) ?
                                    cartSummaryCtrl.userPersonalMinOrderLimit : (Number( area && area.deliveryMinimumCost) || Number(Config.retailer.settings.minimumOrderPrice));
                            return Util.validateMinimumCost(minimumOrderPrice, Config.retailer.notIncludeSpecials, area && area.deliveryMinimumCost && area.name);
                        }).then(function () {
                            $state.go('app.checkout.details');
                        });
                    }
                }

                function tryLogin() {
                    if (cartSummaryCtrl.isLoggedIn) {
                        return true;
                    }

                    Util.openLoginDialog();
                    return false;
                }

                function _quantityOfPseudoInCart(selectedItems) {
                    var quantityOfPseudoInCart = {
                     isTotalCartPseudo: false,
                     isPseudo: false,
                     pseudoCounter: 0
                    };

                    angular.forEach(selectedItems, function (line) {
                        if(line.isPseudo) {
                            quantityOfPseudoInCart.pseudoCounter++
                        }
                    });

                    if(quantityOfPseudoInCart.pseudoCounter === selectedItems.length) {
                        quantityOfPseudoInCart.isTotalCartPseudo = true;
                    }
                    if(quantityOfPseudoInCart.pseudoCounter !== 0) {
                        quantityOfPseudoInCart.isPseudo = true;
                    }

                    return quantityOfPseudoInCart;
                }

                function createNewShopList() {
                    if (!cartSummaryCtrl.isLoggedIn) {
                        return Util.openLoginDialog().then(function () {
                            return createNewShopList();
                        });
                    }

                    return Util.showCommonDialog({
                        title: '{{\'Create New List\' | translate}}',
                        content: '<input ng-model="name" type="text" placeholder="{{\'List name\' | translate}}"/>',
                        buttons: [{
                            text: '{{\'Create list\' | translate}}',
                            click: 'create()'
                        }, {
                            text: '{{\'Cancel\' | translate}}',
                            click: '$dialog.hide()'
                        }],
                        controller: ['$scope', '$element', function ($dialogScope, $element) {
                            $element[0].querySelector('input').focus();
                            $dialogScope.create = function () {
                                var selectedItems = cart.getLines();
                                var quantityOfPseudoInCart = _quantityOfPseudoInCart(selectedItems);
                                if(quantityOfPseudoInCart.isTotalCartPseudo) {
                                    dialog.hide();
                                    return Util.showCommonDialog({
                                        title: '{{\'Oops\' | translate}}...',
                                        content: '<div>{{\'pseudo_products_will_not_be_added_to_saved_shopping_list\' | translate}}</div>'
                                    });
                                }
                                shopListService.create($dialogScope.name, selectedItems).then(function () {
                                    var arr = [];
                                    angular.forEach(selectedItems, function (line) {
                                        arr.push(line.id);
                                    });
                                    $rootScope.$emit('cart.lines.added.to.mylist', arr);
                                    Util.showCommonDialog({
                                        title: '{{\'Shopping List Created\' | translate}}',
                                        content: '{{\'Shopping list named\' | translate}} ' + $dialogScope.name +
                                        ' {{\'was successfully created with\' | translate}} ' +
                                            (quantityOfPseudoInCart.isPseudo ? selectedItems.length - quantityOfPseudoInCart.pseudoCounter : selectedItems.length) +
                                        ' {{\'items\' | translate}}' +
                                            (quantityOfPseudoInCart.isPseudo ? ', <div>{{\'pseudo_products_will_not_be_added_to_created_shopping_list\' | translate}} <div>' : '')
                                    });
                                    _setShopLists();
                                }).catch(function () {
                                    Util.showCommonDialog({
                                        title: '{{\'Oops\' | translate}}...',
                                        content: '<div>{{\'We couldn\\\'t create your shopping list\' | translate}}.</div><div>{{\'Please try again\' | translate}}</div>'
                                    });
                                }).finally(function () {
                                    dialog.hide();
                                });
                            };
                        }]
                    });
                }

                function saveToShopList(shopList) {
                    var selectedItems = cart.getLines();

                    var quantityOfPseudoInCart = _quantityOfPseudoInCart(selectedItems);
                    if(quantityOfPseudoInCart.isTotalCartPseudo) {
                        return Util.showCommonDialog({
                            title: '{{\'Oops\' | translate}}...',
                            content: '<div>{{\'pseudo_products_will_not_be_added_to_saved_shopping_list\' | translate}}</div>'
                        });
                    }

                    shopListService.patchItems(shopList.id, selectedItems).then(function () {
                        var arr = [];
                        angular.forEach(selectedItems, function (line) {
                            arr.push(line.id);
                        });
                        $rootScope.$emit('cart.lines.added.to.mylist', arr);
                        Util.showCommonDialog({
                            title: '{{\'Items Successfully Added\' | translate}}',
                            content: (quantityOfPseudoInCart.isPseudo ? selectedItems.length - quantityOfPseudoInCart.pseudoCounter : selectedItems.length) + ' ' +
                                '{{\'' + (selectedItems.length > 1 ? 'items were' : 'Item was') +
                                ' successfully added to shopping list\' | translate}} ' + shopList.name +
                                (quantityOfPseudoInCart.isPseudo ? ', <div>{{\'pseudo_products_will_not_be_added_to_created_shopping_list\' | translate}} <div>' : '')
                        });
                    }).catch(function () {
                        Util.showCommonDialog({
                            title: '{{\'Oops\' | translate}}...',
                            content: '<div>{{\'Items were not added to shopping list\' | translate}}.</div><div>{{\'Please try again\' | translate}}</div>'
                        });
                    });
                }

                function addPromotionToUser(promoCode) {
                    if (!promoCode) {
                        return;
                    }

                    if (!User.session.userId) {
                        return Util.openLoginDialog().then(function() {
                            return addPromotionToUser(promoCode);
                        });
                    }

                    return Api.request({
                        method: 'GET',
                        url:'/v2/retailers/:rid/promotions/_query',
                        params: {
                            promoCode: promoCode,
                            userId: User.session.userId
                        },

                    }).then(function (res) {
                        localStorage.setItem('promotionId', res.promotion.promotionId);
                        localStorage.setItem('inviteId', res.promotion.inviteId);
                        Util.openUserPromotionDialog({names: res.promotion.names});
                    }).catch(function (err) {
                        _promoCodeValidDialog(err);
                    }).finally(function () {
                        cartSummaryCtrl.promoCode = '';
                    });
                }

                function trapProductsSuggestionsFocus(index) {
                    if (!cartSummaryCtrl.productsSuggestionsShown) {
                        return;
                    }

                    //When popup is open, keep the focus withing the popup
                    var productsSuggestionsWrapper = $element[0].querySelector('.products-suggestions-wrapper'),
                        suggestionsFocusElements = productsSuggestionsWrapper.querySelectorAll('button, [ng-click], [href], input, select, textarea');

                    suggestionsFocusElements && suggestionsFocusElements[index === 0 ?  (suggestionsFocusElements.length -2) : 0].focus();
                }

                function _promoCodeValidDialog(err) {
                    Util.showCommonDialog({
                        title: '{{\'Invalid Promo Code\' | translate}}',
                        content: '<div>{{err.data.error ? err.data.error : \'Promo code unidentified\' | translate}}.</div>'
                    });
                }

                function addCouponToCart(couponCode) {
                    Util.addCouponToCart(couponCode).then(function() {
                        $rootScope.couponCode = "";
                    });
                }

                function _toggleProductsSuggestions(value) {
                    if (angular.isDefined(value)) {
                        cartSummaryCtrl.productsSuggestionsShown = value;
                    } else {
                        cartSummaryCtrl.productsSuggestionsShown = !cartSummaryCtrl.productsSuggestionsShown;
                    }

                    if (cartSummaryCtrl.productsSuggestionsShown) {
                        angular.element(document.querySelector('.products-suggestions-wrapper')).bind('click', _clickToCloseSuggestion);
                        $element[0].querySelector('.products-suggestions-wrapper > .products-suggestions > button.name').focus();

                    } else {
                        angular.element(document.querySelector('.products-suggestions-wrapper')).unbind('click', _clickToCloseSuggestion);
                    }
                }

                function toggleProductsSuggestions(value) {
                    _toggleProductsSuggestions(value);
                    cartSummaryCtrl.productsSuggestionsAnimations = true;
                }

                function _clickToCloseSuggestion() {
                    toggleProductsSuggestions(false);
                    $scope.$apply();
                }

                function _initAdvertisements() {
                    _advertisementsPromises.advertisements = _advertisementsPromises.advertisements || Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/native-promotion',
                        params: {
                            groupType: ADVERTISEMENT_GROUP_TYPE.PRODUCTS,
                        }
                    }).then(function(resp) {
                        return resp && resp.advertisements;
                    });

                    _advertisementsPromises.products = _advertisementsPromises.advertisements.then(function(advertisements) {
                        if (!advertisements || !advertisements.productTagId) {
                            return;
                        }

                        return Api.request({
                            url: '/v2/retailers/:rid/branches/:bid/products',
                            method: 'GET',
                            params: Products.prepareSearchParams({
                                from: 0,
                                size: 10,
                                filters: {
                                    must: {
                                        term: {
                                            'branch.isActive': true,
                                            'branch.isVisible': true,
                                            parentProductTags: advertisements.productTagId
                                        }
                                    }
                                }
                            })
                        });
                    }).then(function(resp) {
                        if (resp && resp.products && resp.products.length) {
                            cartSummaryCtrl.isProductSuggestionsActive = true;
                        } else {
                            cartSummaryCtrl.isProductSuggestionsActive = Config.retailer.isProductSuggestionsActive;
                        }

                        return resp.products || [];
                    }).catch(function(err) {
                        return [];
                    });
                }

                function loadProductSuggestions(from, size, callback) {
                    return _advertisementsPromises.products.then(function(adProducts) {
                        var adProductsInChunk = adProducts.slice(from, from + size);

                        if (!Config.retailer.isProductSuggestionsActive) {
                            return {
                                products: adProductsInChunk,
                                total: adProducts.length
                            };
                        }

                        if (adProductsInChunk.length === size) {
                            return {
                                products: adProductsInChunk,
                                // plus 1 to make the carousel ask for at least one more item and get the real total by the suggestions
                                total: adProducts.length + 1
                            };
                        }

                        var suggestionsFrom = Math.max(from - adProducts.length, 0),
                            suggestionsSize = size - adProductsInChunk.length;

                        if (Config.retailer.settings.showUnavailableProducts !== 'true') {
                            PRODUCT_SUGGESTIONS_FILTERS.mustNot.term['branch.isOutOfStock'] = true;
                        }
                        else {
                            if (Config.retailer.settings.isOutOfStockDuration === 'true') {
                                PRODUCT_SUGGESTIONS_FILTERS.bool = {
                                    "should": [
                                        {
                                            "bool": {
                                                "must_not": {
                                                    "exists": {
                                                        "field": "branch.outOfStockShowUntilDate"
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "range": {
                                                        "branch.outOfStockShowUntilDate": {
                                                        "gt": "now"
                                                        }
                                                    }
                                                    },
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": true
                                                    }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": false
                                                    }
                                                    }
                                                ]
                                            }
                                        }
                                        ]
                                    }
                            }
                        }

                        var params = Products.prepareSearchParams({
                            from: suggestionsFrom,
                            size: suggestionsSize,
                            filters: angular.copy(PRODUCT_SUGGESTIONS_FILTERS)
                        });

                        // remove ad products from the suggestions result
                        if (adProducts.length) {
                            params.filters.mustNot.term.id = adProducts.map(function(product) {
                                return product.id;
                            });
                        }

                        var userPurchasesSortBoost = User.getPurchasesSortBoost();
                        if (userPurchasesSortBoost) {
                            params.sortBoosts = [userPurchasesSortBoost];
                        }

                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/:rid/branches/:bid/products',
                            params: params
                        }).then(function(resp) {
                            return {
                                total: resp.total + adProducts.length,
                                products: adProductsInChunk.concat(resp.products)
                            };
                        });
                    }).then(function(resp) {
                        if (angular.isUndefined(cartSummaryCtrl.productsSuggestionsShown) && previousStateName !== 'app.checkout') {
                            _toggleProductsSuggestions(true);
                        }

                        callback(null, resp.total, cart.getProducts(resp.products));
                    }).catch(function (err) {
                        callback(err);
                    });
                }

                function LastMinuteRecommendationsSections(section, lastMinuteRecommendations) {
                    var self = Object.assign(this, section, _getCarouselData(section.sectionType));

                    if (!self.items || !self.items.length) {
                        self.hasNoItems = true;
                    }

                    self.isLoading = true;
                    _setSoldBy(lastMinuteRecommendations);

                    if (_isRetailerPremiumReplacementsEnabled) {
                        _setReplacement(lastMinuteRecommendations);
                    }

                    function _getCarouselData(sectionType) {
                        switch(sectionType) {
                            case $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.OFFERS:
                                return {
                                    class: lastMinuteRecommendations.settings.config.tableType == $rootScope.LAST_MINUTE_RECOMMENDATIONS.ITEMS_TYPES.SPECIALS
                                        ? 'specials-carousel'
                                        : 'products-carousel',
                                    templateUrl: lastMinuteRecommendations.settings.config.tableType == $rootScope.LAST_MINUTE_RECOMMENDATIONS.ITEMS_TYPES.SPECIALS
                                        ? 'template/views/cart-summary/partials/carousel-special-item/index.html'
                                        : 'template/views/cart-summary/partials/carousel-product-item/index.html',
                                    items: lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.OFFERS],
                                    loadItems: _getItems,
                                    actions: lastMinuteRecommendations.settings.config.tableType == $rootScope.LAST_MINUTE_RECOMMENDATIONS.ITEMS_TYPES.SPECIALS
                                        ? { 
                                            openSpecial: function(specialId, showProductsFrom) {
                                                _sendAnalytics(self.data.analyticsLabel + ' - Open special', this);
                                                Util.showSpecialDetailsDialog(specialId, showProductsFrom, self.data.analyticsLabel + ' - Add product');
                                            } 
                                        }
                                        : { 
                                            openProduct: function(product, itemsData) {
                                                _sendAnalytics(self.data.analyticsLabel + ' - Open product');
                                                Util.openProduct(product, itemsData);
                                            }  
                                        },
                                    data: { analyticsLabel: 'Last minute recommendations - Last-minute offers (' +
                                        (lastMinuteRecommendations.settings.config.tableType == $rootScope.LAST_MINUTE_RECOMMENDATIONS.ITEMS_TYPES.SPECIALS ? 
                                        'Specials' : 'Products') + ')'
                                    },
                                    shownSize: 3
                                }
                            case $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION:
                                return {
                                    class: 'tip-donation-carousel',
                                    templateUrl: 'template/views/cart-summary/partials/carousel-tip-donation-item/index.html',
                                    items: lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.TIP_DONATION],
                                    loadItems: _getItems,
                                    actions: { 
                                        openProduct: function(product, itemsData) {
                                            _sendAnalytics(self.data.analyticsLabel + ' - Open product', this);
                                            Util.openProduct(product, itemsData);
                                        } 
                                    },
                                    data: { analyticsLabel: 'Last minute recommendations - Delivery tip / Donation products' },
                                    shownSize: 2
                                }
                            case $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.COUPONS:
                                return {
                                    class: 'coupons-carousel',
                                    templateUrl: 'template/views/cart-summary/partials/carousel-coupon-item/index.html',
                                    items: lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.COUPONS],
                                    loadItems: _getItems,
                                    actions: { 
                                        openCoupon: function(coupon, terms) {
                                            _sendAnalytics(self.data.analyticsLabel + ' - Open coupon', this);
                                            Util.showCouponDetailsDialog(coupon, terms);
                                        } 
                                    },
                                    data: { analyticsLabel: 'Last minute recommendations - Coupons' },
                                    shownSize: 3
                                }
                            case $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_USUALLY:
                                return {
                                    class: 'products-carousel',
                                    templateUrl: 'template/views/cart-summary/partials/carousel-product-item/index.html',
                                    items: lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_USUALLY],
                                    loadItems: _getItems,
                                    actions: { 
                                        openProduct: function(product, itemsData) {
                                            _sendAnalytics(self.data.analyticsLabel + ' - Open product', this);
                                            Util.openProduct(product, itemsData);
                                        } 
                                    },
                                    data: { analyticsLabel: 'Last minute recommendations - Products the customer usually buys' },
                                    shownSize: 3
                                }
                            case $rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_COMPLEMENTARY:
                                return {
                                    class: 'specials-carousel',
                                    templateUrl: 'template/views/cart-summary/partials/carousel-special-item/index.html',
                                    items: lastMinuteRecommendations.data[$rootScope.LAST_MINUTE_RECOMMENDATIONS.SECTIONS_TYPES.PRODUCTS_COMPLEMENTARY],
                                    loadItems: _getItems,
                                    actions: { 
                                        openSpecial: function(specialId, showProductsFrom) {
                                            _sendAnalytics(self.data.analyticsLabel + ' - Open special', this);
                                            Util.showSpecialDetailsDialog(specialId, showProductsFrom, self.data.analyticsLabel + ' - Add product');
                                        } 
                                    },
                                    data: { analyticsLabel: 'Last minute recommendations - Complementary products on sale' },
                                    shownSize: 3
                                }
                        }
                    }

                    function _getItems(from, size, callback) {
                        self.total = self.items ? self.items.length : 0;
                        _finishLoadingComponent(self);
                        callback(null, self.total, self.items.map(function(item) {
                            item.analyticsLabel = self.data.analyticsLabel;
                            if (item.item) {
                                item.item.analyticsLabel = self.data.analyticsLabel;
                            }
                            return item;
                        }));
                    }

                    function _sendAnalytics(label) {
                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: label}});
                    }
    
                    function _finishLoadingComponent() {
                        if (!self.total) {
                            self.hasNoItems = true;
                        }
    
                        if (self.isLoading) {
                            self.isLoading = false;
                        }
                    }
                }

                function _isCashbackLoyaltyClub(loyaltyClubId) {
                    var isCashbackLoyaltyClub = false;
                    angular.forEach(Config.retailer.loyaltyClubDrivers, function (loyaltyClubDriver) {
                        isCashbackLoyaltyClub = loyaltyClubDriver.clientConfig.loyaltyClubs && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] &&
                            loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback;
                    });

                    return isCashbackLoyaltyClub;
                }

                function _setCashbackLoyaltyClub(loyaltyClubId) {
                    angular.forEach(Config.retailer.loyaltyClubDrivers, function (loyaltyClubDriver) {
                        if(loyaltyClubDriver.clientConfig.loyaltyClubs && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] &&
                            loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback) {
                            cartSummaryCtrl.activeCashbackLoyalty = loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId];
                        }
                    });
                }

                function _setShopLists() {
                    cartSummaryCtrl.isLoggedIn = false;
                    cartSummaryCtrl.shopLists = [];
                    if (User.session.userId) {
                        //get lists
                        shopListService.get().then(function (data) {
                            cartSummaryCtrl.shopLists = data.shopLists;
                        });
                        cartSummaryCtrl.isLoggedIn = true;
                    }
                }

                function _getAreaById(areaId) {
                    var areas = Config.branch.areas;
                    for (var i = 0; i < areas.length; i++) {
                        if (areas[i].id == areaId) {
                            return areas[i];
                        }
                    }
                }

                function _setReplacement(lastMinuteRecommendations) {
                    angular.forEach(lastMinuteRecommendations.data , function(chunks) {
                        var linesForSuggestions = [];
                        
                        angular.forEach(chunks, function (chunk) {

                            if (chunk && chunk.branch && chunk.branch.isOutOfStock) {
                                linesForSuggestions.push(chunk);
                            }
                        });

                        if (linesForSuggestions.length) {
                            Util.setReplacements(linesForSuggestions);
                        }
                    })
                }

                function _setSoldBy(lastMinuteRecommendations) {
                    angular.forEach(lastMinuteRecommendations.data , function(items) {
                        angular.forEach(items, function (item) {
                            item.soldBy = null;
                            if (Util.isUnitsWeighable(item)) {
                                var cartLine = _getLine(item);
                                if (!cartLine) {
                                    var soldBy;
                                    if (!!item.productDisplayModeId) {
                                        switch (item.productDisplayModeId) {
                                            case $rootScope.PRODUCT_DISPLAY.UNIT.id:
                                                soldBy = $rootScope.PRODUCT_DISPLAY.UNIT.name;
                                                break;
                                            case $rootScope.PRODUCT_DISPLAY.WEIGHT.id:
                                                soldBy = $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                                                break;
                                            default:
                                                soldBy = item.isWeighable ? (item.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                        } 
                                    } else {
                                        soldBy = item.isWeighable ? (item.weight ? $rootScope.PRODUCT_DISPLAY.UNIT.name : $rootScope.PRODUCT_DISPLAY.WEIGHT.name) : null;
                                    }
                                    item.soldBy = soldBy;
                                } else {
                                    item.soldBy = cartLine.soldBy;
                                }
                            }
                        })
                    });
                }

                function _getLine(product) {
                    var cartLines = Cart.getLines(),
                        cartLine;
                    if (!cartLines) return;
                    angular.forEach(cartLines, function (line) {
                        if (line.product && line.product.id === product.id) {
                            cartLine = line;
                        }
                    })
                    return cartLine;
                }

                PermanentFilters.subscribe(function() {
                    _initAdvertisements();

                    if (cartSummaryCtrl.suggestionsCarouselFunctions && cartSummaryCtrl.suggestionsCarouselFunctions.reset) {
                        cartSummaryCtrl.suggestionsCarouselFunctions.reset();
                    }
                }, $scope);
                Util.currentScopeListener($scope, $scope.$root.$on('login', _setShopLists));
                Util.currentScopeListener($scope, $scope.$root.$on('logout', _setShopLists));
                Util.currentScopeListener($scope, $scope.$root.$on('document.keydown', function (event, originalEvent) {
                    // escape key maps to keycode `27`
                    if (originalEvent.keyCode === 27 && cartSummaryCtrl.productsSuggestionsShown) {
                        cartSummaryCtrl.productsSuggestionsShown = false;
                        $scope.$apply();
                        event.preventDefault();
                        originalEvent.preventDefault();
                    }
                }));

                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function (event, data) {
                    $q.all({
                        advertisements: _advertisementsPromises.advertisements,
                        products: _advertisementsPromises.products
                    }).then(function(results) {
                        if (!results.advertisements || !results.products.length || !results.advertisements.promotion.events || !data.lines || !data.lines.length) {
                            return;
                        }

                        for (var i = 0; i < results.products.length; i++) {
                            if (results.products[i].id === data.lines[0].product.id) {
                                if (results.advertisements.promotion.adId) {
                                    Util.firePromotionEvent(results.advertisements.promotion, 'banner');
                                    delete results.advertisements.promotion.adId;
                                }
                                Util.firePromotionEvent(results.advertisements.promotion, 'banner');
                                data.lines[0].adConversionUrl = results.advertisements.promotion.events[ADVERTISEMENT_CONVERSION_TYPE.CHARGE];
                                return;
                            }
                        }
                    });

                    if (data.lines[0].product.analyticsLabel) {
                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: data.lines[0].product.analyticsLabel + ' - Add product'}});
                    }
                }));

                $rootScope.$on('spCarousel.event', function (event, data) {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: data.label}});
                });
            }]);

})(angular, app);
