(function (angular, app) {
    'use strict';

    app.directive('spKikarInputWithLongLabel', function () {
        return {
            restrict: 'A',
            link: function ($scope, $element) {
                // Function to update padding
                function updatePadding(_label) {
                    var _labelHeight = _label.offsetHeight;
                    if (_labelHeight) {
                        $element.css('padding-top', 'calc(' + _labelHeight + 'px - 0.5rem');
                    }
                }

                // Set up a MutationObserver to detect changes in the label
                var _parent = $element.parent()[0];
                if (_parent) {
                    var _label = _parent.querySelector('label');
                    if (_label) {
                        var observer = new MutationObserver(function () {
                            updatePadding(_label);
                        });

                        // Observe changes to child nodes and character data
                        observer.observe(_label, {
                            childList: true, // For changes in text nodes
                            characterData: true, // For direct text changes
                            subtree: true // To observe text within child elements
                        });

                        // Cleanup observer on scope destroy
                        $scope.$on('$destroy', function () {
                            observer.disconnect();
                        });
                    }
                }

            }
        };
    });
})(angular, app);