(function (angular, app) {
    'use strict';

    app.directive("spTooltip", [
        '$compile', 'Util', '$window', 
        function ($compile, util, $window) {
        return {
            restrict: "A",
            scope: {
                content: "@?spTooltip",
                isRemovePrevious: '@?spTooltipRemovePrevious',
                isCheckingOver: '=?spTooltipCheckingOver'
            },
            link: function($scope, $element){
                $scope.isRemovePrevious = $scope.isRemovePrevious === 'true';
                function _bind() {
                    var template = $scope.content && "<div class='tooltip-content'>" + $scope.content +"</div>";
                    if (template) {
                        if($scope.isRemovePrevious && $element[0] ){
                            var tooltips =  $element[0].querySelectorAll('.tooltip-content') ;
                            if (tooltips.length > 0) {
                                tooltips[0].remove(); 
                            }
                        }
                        $element.addClass('tooltip');
                        $element.append(template);
                        $compile($element.contents())($scope.$parent);
                        if ($scope.isCheckingOver) {
                            $element.on('mouseenter', function() {
                                var tooltipContentElement = $element[0].querySelector('.tooltip-content');

                                if (tooltipContentElement) {
                                    var tooltipRect = tooltipContentElement.getBoundingClientRect();
                                    if (tooltipRect.left < 0) {
                                        tooltipContentElement.style.left = '100%';
                                    } else if (tooltipRect.right > $window.innerWidth) {
                                        tooltipContentElement.style.left = '34%';
                                    }
                                    if (tooltipRect.top < 0) {
                                        tooltipContentElement.style.top = '10px'; 
                                    } else if (tooltipRect.bottom > $window.innerHeight) {
                                        tooltipContentElement.style.top = ($window.innerHeight - tooltipRect.height - 10) + 'px';
                                    }
                                }
                            });
                        }
                    }
                }

                util.currentScopeListener($scope, $scope.$watch('content', _bind));
            }
        };
    }]);
})(angular, app);