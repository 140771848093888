(function (angular, app) {
    'use strict';

    var DEFAULT_SEARCH_PARAMS = {
        filters: {
            must: {
                exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
                term: {
                    'branch.isActive': true,
                    'branch.isVisible': true
                }
            },
            mustNot: {
                term: {
                    'branch.regularPrice': 0
                }
            }
        }
    };

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.searchProducts', {
                url: '/search/:query?{filter}&{filters:json}&{sort:json}&{title}',
                data: {
                    name: 'search-products'
                },
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Search'));
                    }]
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/search-products/index.html',
                        controller: 'SearchProductsCtrl as searchCtrl'
                    }
                }
            });
        }])
        .controller('SearchProductsCtrl', [
            '$scope', '$stateParams', '$rootScope', '$location', '$q', 'Config', 'Util', 'Cart', 'Api',
            'Dialog', 'Items', 'FilterManager', 'User', 'DataLayer',
            function ($scope, $stateParams, $rootScope, $location, $q, config, util, cart, api,
                      dialog, items, FilterManager, User, DataLayer) {
                var searchCtrl = this,
                    _isRetailerPremiumReplacementsEnabled = config.retailer.settings.enablePersonalReplacement;
0
                angular.extend(searchCtrl, {
                    total: 0,
                    products: [],
                    title: $stateParams.query || $stateParams.title,
                    openProduct: openProduct,
                    onSearchProducts: onSearchProducts,
                    productTagsFilter: FilterManager.getProductTagsFilter(_onChange, $scope, false),
                    brandFilter: FilterManager.getBrandsFilter(_onChange),
                    categoriesFilter: FilterManager.getCategoriesFilter(_onChange)
                });

                var filtersParams = angular.copy(DEFAULT_SEARCH_PARAMS);
                filtersParams.query = $stateParams.query;
                filtersParams.languageId = config.language.id;
                _addStateFilters(filtersParams);

                FilterManager.setFiltersFromUrl({
                    productTag: searchCtrl.productTagsFilter,
                    category: searchCtrl.categoriesFilter,
                    brand: searchCtrl.brandFilter
                }, $stateParams.filters);

                api.request({
                    url: '/v2/retailers/:rid/branches/:bid/products/filters',
                    params: filtersParams
                }, {
                    fireAndForgot: true
                }).then(function (results) {
                    FilterManager.addProductTagsFilterOptions(searchCtrl.productTagsFilter, results.productTags);
                    FilterManager.addBrandsFilterOptions(searchCtrl.brandFilter, results.brands);
                    FilterManager.addCategoriesFilterOptions(searchCtrl.categoriesFilter, results.categories);
                }).finally(function () {
                    FilterManager.markFiltersAsFinished([searchCtrl.productTagsFilter, searchCtrl.brandFilter, searchCtrl.categoriesFilter]);
                });

                function openProduct(product, index) {
                    util.openProduct(product, {
                        next: _nextProduct,
                        previous: _previousProduct,
                        siblings: [items.items[index - 1], items.items[index - 2], items.items[index + 1], items.items[index + 2]],
                        total: searchCtrl.total,
                        index: index
                    });
                }

                function onSearchProducts(query) {
                    if ((!query || query.length < 3) && !$stateParams.query) {
                        return;
                    }

                    $stateParams.query = !query || query.length < 3 ? '' : query;
                    items.resetItems();
                    searchCtrl.products.splice(0, searchCtrl.products.length);
                    return _addChunkToProducts(true);

                }

                function _addChunkToProducts(isInit) {
                    var totalItems = items.getItemsLength();
                    if (!isInit && totalItems == searchCtrl.total) return;

                    return items.getChunkAmount().then(function(size) {
                        var params = angular.copy(DEFAULT_SEARCH_PARAMS);
                        params.from = totalItems;
                        params.size = size;
                        params.query = $stateParams.query;
                        params.languageId = config.language.id;
                        params.isSearch = true;
                        if (config.retailer.settings.showUnavailableProducts !== 'true') {
                            params.filters.mustNot.term = {
                                "branch.regularPrice": 0,
                                "branch.isOutOfStock": true
                            }
                        }
                        else {
                            if (config.retailer.settings.isOutOfStockDuration === 'true') {
                                params.filters.bool = {
                                    "should": [
                                        {
                                            "bool": {
                                                "must_not": {
                                                    "exists": {
                                                        "field": "branch.outOfStockShowUntilDate"
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "range": {
                                                        "branch.outOfStockShowUntilDate": {
                                                        "gt": "now"
                                                        }
                                                    }
                                                    },
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": true
                                                    }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "bool": {
                                                "must": [
                                                    {
                                                    "term": {
                                                        "branch.isOutOfStock": false
                                                    }
                                                    }
                                                ]
                                            }
                                        }
                                        ]
                                    }
                            }
                        }

                        FilterManager.parseFilters(params, [searchCtrl.productTagsFilter, searchCtrl.brandFilter, searchCtrl.categoriesFilter]);

                        _addStateFilters(params);

                        _addSearchSortBoost(params);

                        return items.getItems({
                            url: '/v2/retailers/:rid/branches/:bid/products',
                            params: params
                        });
                    }).then(function (resp) {
                        var chunks = items.insertBumpersToChunk(cart.getProducts(resp.products))
                        if (_isRetailerPremiumReplacementsEnabled) {
                            var linesForSuggestions = [];
                            
                            angular.forEach(chunks, function (chunk) {

                                if (chunk && chunk.branch && chunk.branch.isOutOfStock) {
                                    linesForSuggestions.push(chunk);
                                }
                            });

                            if (linesForSuggestions.length) {
                                util.setReplacements(linesForSuggestions);
                            }

                        }
                        util.pushAll(searchCtrl.products, chunks);
                        sendSearchRequestToExposeBox($stateParams.query,searchCtrl.products);
                        searchCtrl.total = resp.total === 0 || resp.total ? resp.total : searchCtrl.total;

                        //== Send to Data Layer only for search and not for the scroll action
                        if(!totalItems) {
                            DataLayer.push(DataLayer.EVENTS.SEARCH, {data: {query: $stateParams.query, results: resp.total}});
                        }

                        return resp;
                    });
                }

                function sendSearchRequestToExposeBox(query, lines){
                    var products =[];
                    var categories =[];
                    for (var i = 0; i < lines.length && i < 6; i++) {
                        products.push(lines[i].id);
                    }

                    $rootScope.$emit('productSearch', query, products, prepareCategoriesForSearchRequestToExposeBox());
                }

                function prepareCategoriesForSearchRequestToExposeBox(){
                    var productTags = FilterManager.getProductTagNames(searchCtrl.productTagsFilter.value, config.language.id);
                    var categoryFilters =[];
                    angular.forEach(searchCtrl.categoriesFilter.value, function(filter) {
                        categoryFilters.push(filter.name[config.language.id]);
                    });

                    var brandFilters =[];
                    angular.forEach(searchCtrl.brandFilter.value, function(filter) {
                        if (filter.name[config.language.id]){
                            brandFilters.push(filter.name[config.language.id]);
                        }
                        else{
                            var keysArray = Object.keys(filter.name);
                            if (keysArray.length > 0){
                                brandFilters.push(filter.name[keysArray[0]]);
                            }
                        }

                    });
                    return {productTags: productTags, categoryFilters: categoryFilters, brandFilters: brandFilters};

                }

                function _addStateFilters(params) {
                    if (!$stateParams.filter) {
                        return;
                    }

                    if ($stateParams.filter == 'specials') {
                        params.filters.must.exists.push('branch.specials');
                    } else if ($stateParams.filter !== 'all' && !isNaN($stateParams.filter)) {
                        params.filters.must.term['family.categoriesPaths.id'] = $stateParams.filter;
                    }
                }

                function _addSearchSortBoost(params) {
                    var sortBoosts = [];

                    var userPurchasesSortBoost = User.getPurchasesSortBoost();
                    if (userPurchasesSortBoost) {
                        sortBoosts.push(userPurchasesSortBoost);
                    }

                    var productTagsSortBoost = FilterManager.getProductTagsSortBoost(searchCtrl.productTagsFilter);
                    if (productTagsSortBoost) {
                        sortBoosts.push(productTagsSortBoost);
                    }

                    if (sortBoosts.length) {
                        params.sortBoosts = sortBoosts;
                    }
                }

                function _onChange(newValue, oldValue) {
                    if ((newValue || oldValue) && ((newValue.length && newValue.length == oldValue.length) || (newValue != oldValue && (newValue.length || oldValue.length)))) {
                        FilterManager.setFiltersToUrl({
                            productTag: searchCtrl.productTagsFilter,
                            category: searchCtrl.categoriesFilter,
                            brand: searchCtrl.brandFilter
                        });

                        items.resetItems();
                        searchCtrl.products.splice(0, searchCtrl.products.length);
                        return _addChunkToProducts(true);
                    }
                }

                function _nextProduct(index) {
                    var nextProductData = items.getNextProductData(index, 1),
                        totalItemsWithoutBumpers = items.getItemsLength();
                    if (searchCtrl.total != totalItemsWithoutBumpers && !nextProductData.product) {
                        return _addChunkToProducts().then(function () {
                            return _nextProduct(index);
                        });
                    }

                    nextProductData.total = items.items.length + searchCtrl.total - totalItemsWithoutBumpers;
                    return $q.resolve(nextProductData);
                }

                function _previousProduct(index) {
                    var nextProductData = items.getNextProductData(index, -1),
                        totalItemsWithoutBumpers = items.getItemsLength();
                    nextProductData.total = items.items.length + $scope.total - totalItemsWithoutBumpers;
                    return $q.resolve(nextProductData);
                }

                util.currentScopeListener($scope, $rootScope.$on('items.initiated', function () {
                    _addChunkToProducts(true);
                }));

                util.currentScopeListener($scope, $rootScope.$on('document.scroll.end', function (data, event) {
                    event.start();
                    var promise = _addChunkToProducts();
                    if (promise) {
                        promise.finally(function () {
                            event.done();
                        });
                    } else {
                        event.done();
                    }
                }));

                util.currentScopeListener($scope, $rootScope.$on('items.add', _addChunkToProducts));

                util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                    angular.forEach(searchCtrl.products, function (product, index) {
                        if (product.isBumper) return;
                        searchCtrl.products[index] = cart.getProduct(product);
                    });
                }));
            }])

        //those controllers should move to their template
        .controller('PrutahTemplateSearchProductsCtrl', ['Items', function(Items) {
            Items.initPage();
        }])
        .controller('KikarTemplateSearchProductsCtrl', ['Items', function(Items) {
            Items.initPage({
                defaultWidth: 235 // 235
            });
        }]);

})(angular, app);
