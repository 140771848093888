(function (angular, app) {
    'use strict';

    app.controller('FooterCtrl', [ '$state', 'Config', 'Util', 'LINK_LOCATIONS', 'WEB_PAGE_LOCATIONS',
        function ($state, Config, Util, LINK_LOCATIONS, WEB_PAGE_LOCATIONS) {
            var footerCtrl = this;
            footerCtrl.links = [];
            footerCtrl.checkIfIsPDF = checkIfIsPDF;
            //add home link
            footerCtrl.links.push({
                href: $state.href('app.home'),
                text: 'Home'
            });

            //add store info link
            footerCtrl.links.push({
                href: $state.href('app.retailerDetails.storeInformation'),
                text: Config.retailer.branches.length > 1 ? 'Stores Info' : 'Store Info'
            });

            //add about us link
            if (Config.retailer.settings.aboutUsText) {
                footerCtrl.links.push({
                    href: $state.href('app.about'),
                    text: 'About Us'
                });
            }

            //add my account link
            footerCtrl.links.push({
                href: $state.href('app.userEdit'),
                text: 'My Account'
            });

            //add privacy policy link
            if (Config.retailer.settings.privacyText) {
                footerCtrl.links.push({
                    href: $state.href('app.policies'),
                    text: 'Privacy Policy'
                });
            }

            //add accessibility statement link
            if (Config.retailer.settings.accessibilityStatement) {
                footerCtrl.links.push({
                    href: $state.href('app.accessibilityStatement'),
                    text: 'Accessibility Statement'
                });
            }

            //add contact us link
            if (Config.retailer.settings.isHiddenCrmActive !== 'true') {
            footerCtrl.links.push({
                action: Util.openContactUs,
                text: 'Contact Us'
            });
        }

            //add terms & conditions link
            if (Config.retailer.settings.agreementText) {
                footerCtrl.links.push({
                    href: $state.href('app.termsAndConditions'),
                    text: 'Terms & Conditions'
                });
            }

            //add faq link
            if ((Config.retailer.settings.faq || '').toString().toLowerCase() === 'true') {
                footerCtrl.links.push({
                    href: $state.href('app.retailerDetails.faq'),
                    text: 'FAQ'
                });
            }

            //add sitemap link
            footerCtrl.links.push({
                href: $state.href('app.sitemap'),
                text: 'Sitemap'
            });

            angular.forEach(Config.retailer.toolbar || [], function (link, key) {
                if (link.location !== LINK_LOCATIONS.BOTTOM) {
                    return;
                }
                checkIfIsPDF(link.url)
                footerCtrl.links.push({
                    href: link.embedded && !Util.isInternalUrl(link.url) ? $state.href('app.links', {linkId: link.id}) : link.url,
                    name: link.name,
                    pdf: checkIfIsPDF(link.url),
                    target: link.embedded ? undefined : '_blank',
                    image: link.imageUrl
                });
            });

            angular.forEach(Config.retailer.publishedWebPages, function (webPage) {
                if (webPage.locationInWebsite !== WEB_PAGE_LOCATIONS.FOOTER) {
                    return;
                }

                footerCtrl.links.push({
                    href: $state.href('app.webPage', {id: webPage.id}),
                    text: webPage.name,
                    image: webPage.iconUrl
                });
            });

            function checkIfIsPDF(file){
                try {
                    var url = new URL(file);
                    return url.protocol === 'http:' || url.protocol === 'https:';
                } catch (err) {
                    return false;
                }
            }
        }]);
})(angular, app);
